import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type QuestionKey =
  | "isValidUser"
  | "Questionnaire1"
  | "Questionnaire2"
  | "Questionnaire3";

type status = "INCOMPLETE" | "COMPLETE";
export interface State {
  isValidUser: boolean;
  questionnaireOne: status;
  questionnaireTwo: status;
  questionnaireThree: status;
}

export const initialState: State = {
  isValidUser: false,
  questionnaireOne: "INCOMPLETE",
  questionnaireTwo: "INCOMPLETE",
  questionnaireThree: "INCOMPLETE",
};

export const QuestionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    isValidUser: (state, action: PayloadAction<boolean>) => {
      state.isValidUser = action.payload;
    },

    completedQuestionnaireOne: (state) => {
      state.questionnaireOne = "COMPLETE";
    },

    completedQuestionnaireTwo: (state) => {
      state.questionnaireTwo = "COMPLETE";
    },

    completedQuestionnaireThree: (state) => {
      state.questionnaireThree = "COMPLETE";
    },
  },
});

export const {
  isValidUser,
  completedQuestionnaireThree,
  completedQuestionnaireOne,
  completedQuestionnaireTwo,
} = QuestionnaireSlice.actions;
export default QuestionnaireSlice.reducer;
