import VerticalCenter from "../common/components/VerticalCenter";
import { RootState } from "../app/Store";
import { useStore } from "react-redux";
import { selectRecievedWish } from "../features/listenerProfile/select";
import Center from "../common/components/Center";
import { useDispatch } from "react-redux";
import { goToNextStep } from "../features/journey";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import Button from "../common/components/Button";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
const ReceivedWord = () => {
  const state = useStore().getState() as RootState;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const wish = selectRecievedWish(state);

  const flowerColours = [
    "--red-filter",
    "--yellow-filter",
    "--blue-filter",
    "--green-filter",
  ];

  useEffect(() => {
    // Set a timeout to add the fade class after 10 seconds
    setTimeout(() => {
      const element = document.querySelector(".received-word-text");
      if (element) {
        element.classList.add("fade");
      }
    }, 7000); // 7000 milliseconds = 7 seconds
  });

  return (
    <VerticalCenter>
      <div className="star-container">
        <video autoPlay muted loop>
          <source
            src="assets/animations/word-of-encouragement.mp4"
            type="video/mp4"
          />
        </video>
        <div className="star-content" id="received-word">
          <Center>
            <div
              className="flower-filter enter-animation"
              aria-label="A monochromatic flower icon with wispy fluffy
                petals. Superimposed on top is the wihs that you just wrote!."
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                filter: `var(${
                  flowerColours[
                    Math.floor(Math.random() * flowerColours.length)
                  ]
                })`,
              }}
            ></div>
          </Center>
          <Center>
            <Padding>
              <Stack>
                <h1>{t("Someone here sent this to you.")}</h1>
                <p className="received-word-text">
                  <em>{wish}</em>
                </p>
                <Button onClick={() => dispatch(goToNextStep())}>
                  {t("Continue")}
                </Button>
              </Stack>
            </Padding>
          </Center>
        </div>
      </div>
    </VerticalCenter>
  );
};

export default ReceivedWord;
