import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import { RootState } from "../app/Store";
import { recievedWish } from "../features/listenerProfile";
import { selectUserId } from "../features/listenerProfile/select";
import * as WishProxy from "../features/WishProxy";

import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Button from "../common/components/Button";

export default function WordOfEncouragement() {
  const { t } = useTranslation();
  const state = useStore().getState() as RootState;
  const dispatch = useDispatch();

  const userId = selectUserId(state);
  const [wish, setWish] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const hasText = wish.length > 0;

  if (hasSubmitted) {
    return (
      <VerticalCenter>
        <div className="star-container">
          <video autoPlay muted loop>
            <source
              src="assets/animations/word-of-encouragement.mp4"
              type="video/mp4"
            />
          </video>
          <div className="star-content">
            <Center>
              <div
                className="flower-filter exit-animation"
                aria-label="A monochromatic flower icon with wispy fluffy
                petals. Superimposed on top is the wihs that you just wrote!."
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="flower-filter-input">
                  <p>
                    <em>{wish}</em>
                  </p>
                </div>
              </div>
            </Center>
          </div>
        </div>
      </VerticalCenter>
    );
  }

  return (
    <VerticalCenter>
      <div className="star-container">
        <video autoPlay muted loop>
          <source
            src="assets/animations/word-of-encouragement.mp4"
            type="video/mp4"
          />
        </video>
        <div className="star-content">
          <Center>
            <div
              className="flower-filter exit-animation"
              aria-label="A monochromatic flower icon with wispy fluffy
                petals. Superimposed on top is a grey rectangular field with a space
                to write a wish."
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flower-filter-input">
                <div className="code-input">
                  <textarea
                    style={{ textAlign: "center" }}
                    rows={2}
                    className="wish-input"
                    onChange={(e) => setWish(e.currentTarget.value)}
                    placeholder={t("Your words of encouragement")}
                  />
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                if (hasText) {
                  WishProxy.sendWishText(userId, wish).then((response) => {
                    setHasSubmitted(true);
                    dispatch(recievedWish(response.wish));
                  });
                }
              }}
            >
              {t("Send")}
            </Button>
          </Center>
        </div>
      </div>
    </VerticalCenter>
  );
}
