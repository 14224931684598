import { useTranslation } from "react-i18next";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import Center from "../common/components/Center";
import CanYouHearAudioYesNo from "./CanYouHearAudioYesNo";

const CanYouHearAudio = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h1>
            {t("Can you hear a sound playing? If not, turn your ringer on.")}
          </h1>
        </Center>
      </Padding>
      <CanYouHearAudioYesNo />
    </VerticalCenter>
  );
};

export default CanYouHearAudio;
