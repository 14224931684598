import { useStore } from "react-redux";
import { selectAudioLibrary } from "../../features/acoustics/select";
import { AudioName, Status } from "../../features/acoustics";
import { RootState } from "../../app/Store";

import VerticalCenter from "./VerticalCenter";
import Center from "./Center";
import Padding from "./Padding";
import AudioFlower from "./AudioFlower";

interface AudioStatusProps {
  audioNames: AudioName[];
}

const AudioLoadingStatus = (props: AudioStatusProps) => {
  const state = useStore().getState() as RootState;
  const audioLibrary = selectAudioLibrary(state);

  const { audioNames } = props;
  const showOverlayStatus: Status[] = ["LOADING", "NOT_LOADED"];
  const audios = audioNames.map((audioName) => audioLibrary[audioName]);
  const audioStatus = audios.filter((audio) =>
    showOverlayStatus.includes(audio.status)
  );
  const showOverlay = audioStatus.length > 0;

  if (showOverlay) {
    return (
      <div
        className="audioLoading"
        style={{
          display: showOverlay ? "" : "none",
        }}
      >
        <VerticalCenter>
          <Padding>
            <Padding>
              <Center>
                <div className="flower-rotation">
                  <AudioFlower animate={false} />
                </div>
              </Center>
            </Padding>
          </Padding>
          <Padding>
            <Padding>
              <Center>
                <h1>Loading Audio</h1>
              </Center>
            </Padding>
          </Padding>
        </VerticalCenter>
      </div>
    );
  }

  return <></>;
};

export default AudioLoadingStatus;
