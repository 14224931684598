import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Center from "../common/components/Center";
import HorizontalSlider from "../common/components/HorizontalSlider";
import { pressing } from "../features/listenerProfile";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";

export default function Question3Pressing() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("What is most pressing for you right now?")}</h1>
        </Center>
      </Padding>
      <VerticalCenter>
        <HorizontalSlider
          leftElement={<p>{t("Change myself")}</p>}
          values={[1, 2.33, 3.66, 5]}
          defaultValue={2.33}
          onChange={(value) => dispatch(pressing(value))}
          rightElement={<p>{t("Change the world")}</p>}
        />
      </VerticalCenter>
    </>
  );
}
