import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { RootState } from "../../app/Store";
import { getPathStepByIndex } from "../../features/journey/paths/Paths";
import { selectPathType, selectStepIndex } from "../../features/journey/select";

const DEFAULT_COUNTDOWN_SECS = 30;

interface CountdownToNextStepProps {
  onCountdownExpired: () => void;
}

const CountdownToNextStep = (props: CountdownToNextStepProps) => {
  const state = useStore().getState() as RootState;
  const stepIndex = selectStepIndex(state);
  const pathType = selectPathType(state);
  const step = getPathStepByIndex(pathType, stepIndex);

  const SECONDS_TO_NEXT_STEP =
    step?.allowNext?.config?.secondsToNextStep ?? DEFAULT_COUNTDOWN_SECS;

  const [secondsLeft, setSecondsLeft] = useState(SECONDS_TO_NEXT_STEP);

  useEffect(() => {
    setSecondsLeft(SECONDS_TO_NEXT_STEP);
  }, [step]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setSecondsLeft((oldSeconds) => oldSeconds - 1);
    }, 1000);

    return () => {
      setSecondsLeft(DEFAULT_COUNTDOWN_SECS);
      clearInterval(timerId);
    };
  }, []);

  // Move to next step when countdown expires
  useEffect(() => {
    if (secondsLeft <= -1) {
      props.onCountdownExpired();
    }
  }, [secondsLeft]);

  return (
    <progress
      value={SECONDS_TO_NEXT_STEP - secondsLeft}
      max={SECONDS_TO_NEXT_STEP}
    />
  );
};
export default CountdownToNextStep;
