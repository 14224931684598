import UAParser from "ua-parser-js";

const Detector = new UAParser();

const getDevice = () =>
  JSON.stringify({
    ...Detector.getDevice(),
    os: `${Detector.getOS().name} - ${Detector.getOS().version} `,
  });

const getBrowser = () =>
  JSON.stringify({
    name: Detector.getBrowser().name,
    version: Detector.getBrowser().version,
  });

export const getDeviceAndBrowser = () => ({
  device: getDevice(),
  browser: getBrowser(),
});
