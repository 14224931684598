import { Step } from "../paths/Paths";
import AudioLoadingStatus from "../../../common/components/AudioLoadingStatus";
import { AudioName } from "../../acoustics";

interface LoadingAudioProps {
  step: Step;
}

const LoadingAudio = (props: LoadingAudioProps) => {
  const { step } = props;

  return (
    <>
      {step.acoustics && (
        <AudioLoadingStatus
          audioNames={Object.keys(step.acoustics) as Array<AudioName>}
        />
      )}
    </>
  );
};
export default LoadingAudio;
