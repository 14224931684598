import { t } from "i18next";
import Center from "./Center";
import Padding from "./Padding";

interface AudioPlayingProps {
  title: string;
  subtitle: string;
}

const AudioPlaying = (props: AudioPlayingProps) => {
  return (
    <Center>
      <Padding>
        <img
          width="70%"
          src="assets/images/3_Headphones.png"
          alt={t("Headphones")}
        />
        <h1>{props.title}</h1>
        <h2>{props.subtitle}</h2>
      </Padding>
    </Center>
  );
};
export default AudioPlaying;
