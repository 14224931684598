import { Dispatch } from "redux";
import { RootState } from "../../../app/Store";
import { AudioName } from "../../acoustics/";

import {
  atHomePathSteps,
  continueRestartPathSteps,
  inPersonPathSteps,
  setupPathSteps,
  stallContinue,
} from "./PathDefinitions";

export type PathType =
  | "UNDEFINED"
  | "AT_HOME"
  | "IN_PERSON"
  | "SETUP"
  | "TEST"
  | "STALL_CONTINUE"
  | "CONTINUE_RESTART";

export type AllowNextOn =
  | "MANUAL_CLICK"
  | "COMPONENT_DECIDES"
  | "AUDIO_PLAYBACK_COMPLETE"
  | "COUNTDOWN"
  | "NEVER";

export interface AllowNextConfig {
  secondsToNextStep?: number;
  audioCompletion?: AudioName;
  showProgressBar?: boolean;
  footerButtonText?: string;
}

export interface AllowNext {
  allowNextOn: AllowNextOn;
  config?: AllowNextConfig;
}

export type StepAnimation = "SLIDE_LEFT" | "SLIDE_UP";

export interface Path {
  type: PathType;
  steps: Step[];
  saveProgress: boolean;
  canFastTravel: boolean;
}

type DispatchOnEnterFunction = (state: RootState, dispatch: Dispatch) => void;
export type Acoustics = Partial<Record<AudioName, AcousticConfig>>;

export interface AcousticConfig {
  volume: number;
  secondsUntilFadeOut: number;
  secondsUntilFadeIn: number;
  fadeInSeconds: number;
}

export interface Step {
  component: JSX.Element;
  name: string;
  allowNext: AllowNext;
  acoustics?: Acoustics;
  dispatchOnEnter?: DispatchOnEnterFunction;
  onExitAnimation?: StepAnimation;
}

const AtHomeJourneyPath: Path = {
  type: "AT_HOME",
  steps: atHomePathSteps,
  saveProgress: true,
  canFastTravel: true,
};

const InPersonJourneyPath: Path = {
  type: "IN_PERSON",
  steps: inPersonPathSteps,
  saveProgress: true,
  canFastTravel: true,
};

const ContinueRestartJourneyPath: Path = {
  type: "CONTINUE_RESTART",
  steps: continueRestartPathSteps,
  saveProgress: false,
  canFastTravel: false,
};

const SetupJourneyPath: Path = {
  type: "SETUP",
  steps: setupPathSteps,
  saveProgress: false,
  canFastTravel: false,
};

const StallContinuePath: Path = {
  type: "STALL_CONTINUE",
  steps: stallContinue,
  saveProgress: false,
  canFastTravel: false,
};

const ensureUniqueStepNames = (pathType: PathType, steps: Step[]) => {
  const nonUniqueNames = steps
    .map(({ name }) => name)
    .reduce((names: string[], stepName: string) => {
      if (names.includes(stepName)) {
        return [...names];
      }
      return [...names, stepName];
    }, []);

  return nonUniqueNames.length !== steps.length;
};

/**
 *  this whole file could be further decomposed. If getPath
 * took a reference to resolve journey by pathType we could have a singular refernence
 * for augmenting steps
 */

const getPath = (pathType: PathType): Path => {
  let journeyPath;
  switch (pathType) {
    case "SETUP":
      journeyPath = SetupJourneyPath;
      break;
    case "AT_HOME":
      journeyPath = AtHomeJourneyPath;
      break;
    case "IN_PERSON":
      journeyPath = InPersonJourneyPath;
      break;
    case "STALL_CONTINUE":
      journeyPath = StallContinuePath;
      break;
    case "CONTINUE_RESTART":
      journeyPath = ContinueRestartJourneyPath;
      break;

    default:
  }

  if (
    journeyPath &&
    ensureUniqueStepNames(journeyPath.type, journeyPath.steps)
  ) {
    throw new Error(`Path ${pathType} has a duplicate step name`);
  }

  if (!journeyPath) {
    throw new Error(`Unable to determine a journey for ${journeyPath}`);
  }

  return journeyPath;
};

export const getPathFirstStepName = (pathType: PathType): Step => {
  const journey = getPath(pathType);
  return journey.steps[0];
};

export const getPathStepNames = (pathType: PathType) =>
  getPath(pathType).steps.map(({ name }) => name);

export const getPathStepByIndex = (pathType: PathType, stepIndex: number) =>
  getPath(pathType).steps[stepIndex];

export default getPath;
