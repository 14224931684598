import { all, select, takeEvery } from "@redux-saga/core/effects";
import { goToNextStep, goToStepIndex, startJourney, State } from ".";
import * as LocalStoragePersister from "../../common/LocalStoragePersister";
import getPath from "./paths/Paths";
import { enableStallPrevention } from "../application/ApplicationReducer";

export function* sendAppStateToLocalStorage() {
  const journey: State = yield select((state): State => state.journey);
  const saveProgress = getPath(journey.pathType).saveProgress;

  if (saveProgress) {
    const appState: State = yield select((state) => state);
    LocalStoragePersister.save(appState);
  }
}

export function* reactions() {
  yield all(
    yield takeEvery(
      [
        startJourney.type,
        goToNextStep.type,
        goToStepIndex.type,
        enableStallPrevention.type,
      ],
      sendAppStateToLocalStorage
    )
  );
}
