import { useState } from "react";
import {
  getAllErasWithValues,
  getGenresByEraValue,
  getMoodsByEraAndGenreValue,
} from "../../features/acoustics/EraGenreMood/EraGenreMood";
import PillSingleSelect, { Option } from "./PillSingleSelect";
import { useTranslation } from "react-i18next";
import Center from "./Center";
import Padding from "./Padding";

type ValueChanged = (value: number) => void;
interface EraGenreMoodSelectProps {
  onEraSelect: ValueChanged;
  onGenreSelect: ValueChanged;
  onMoodSelect: ValueChanged;
  era: number;
  genre: number;
  mood: number;
}

const EraGenreMoodSelect = (props: EraGenreMoodSelectProps) => {
  const { t } = useTranslation();
  const [eraValue, setEraValue] = useState(props.era);
  const [genreValue, setGenreValue] = useState(props.genre);
  const [moodValue, setMoodValue] = useState(props.mood);

  const eraGenres = getGenresByEraValue(eraValue);
  const eraGenreMoods = getMoodsByEraAndGenreValue(
    eraValue,
    genreValue,
    moodValue
  );

  const allErasWithValues = getAllErasWithValues();

  return (
    <>
      <div>
        <Center>
          <pre>{t("SELECT A DECADE")}</pre>
        </Center>
        <Padding>
          <PillSingleSelect
            defaultSelectedValue={props.era}
            onClick={(eraValue) => {
              setEraValue(eraValue);
              props.onEraSelect(eraValue);
            }}
            options={allErasWithValues.map(
              ({ era, value }) => ({ name: era.toUpperCase(), value } as Option)
            )}
          />
        </Padding>
      </div>
      <div id="genre-select">
        <Center>
          <pre>{t("SELECT A GENRE")}</pre>
        </Center>
        <Padding>
          <PillSingleSelect
            defaultSelectedValue={props.genre}
            onClick={(genreValue) => {
              setGenreValue(genreValue);
              props.onGenreSelect(genreValue);
            }}
            options={eraGenres.map(
              ({ genre, value }) =>
                ({ name: genre.toLocaleUpperCase(), value } as Option)
            )}
          />
        </Padding>
      </div>
      <div id="mood-select">
        <Center>
          <pre>{t("SELECT A MOOD")}</pre>
        </Center>
        <Padding>
          <PillSingleSelect
            defaultSelectedValue={props.mood}
            onClick={(moodValue) => {
              setMoodValue(moodValue);
              props.onMoodSelect(moodValue);
            }}
            options={eraGenreMoods.map(
              ({ mood, value }) =>
                ({ name: mood.toLocaleUpperCase(), value } as Option)
            )}
          />
        </Padding>
      </div>
    </>
  );
};

export default EraGenreMoodSelect;
