import useSystemAudio from "./useSystemAudio";

interface ButtonProps {
  style?: React.CSSProperties;
  onClick: () => void;
  className?: string;
  key?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

const Button = (props: ButtonProps) => {
  const { style, onClick, className, key, disabled = false, children } = props;
  const { click } = useSystemAudio();

  return (
    <button
      style={style}
      className={className}
      key={key}
      disabled={disabled}
      onClick={() => {
        click.play();
        onClick();
      }}
    >
      {children}
    </button>
  );
};

export default Button;
