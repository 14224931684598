import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import EraGenreMoodSelect from "../common/components/EraGenreMoodSelect";
import Padding from "../common/components/Padding";
import {
  initialState,
  whatEraNow,
  whatGenreNow,
  whatMoodNow,
} from "../features/listenerProfile";
import Center from "../common/components/Center";

const Question1WhatMusicNow = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedEraValue, setSelectedEraValue] = useState(initialState.eraNow);
  const [selectedGenreValue, setSelectedGenreValue] = useState(
    initialState.genreNow
  );
  const [, setSelectedMoodValue] = useState(initialState.moodNow);

  useEffect(() => {
    setSelectedGenreValue(initialState.genreYoung);
    setSelectedMoodValue(initialState.moodYoung);
  }, [selectedEraValue]);

  useEffect(() => {
    setSelectedMoodValue(initialState.moodYoung);
  }, [selectedGenreValue]);

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("Which music do you listen to now?")}</h1>
        </Center>
      </Padding>
      <Padding>
        <EraGenreMoodSelect
          era={1}
          genre={1}
          mood={1}
          onEraSelect={(eraValue: number) => {
            dispatch(whatEraNow(eraValue));
            setSelectedEraValue(eraValue);

            const genreSelect = document.getElementById("genre-select");
            if (genreSelect) {
              genreSelect.style.display = "block";
            }
          }}
          onGenreSelect={(genreValue: number) => {
            dispatch(whatGenreNow(genreValue));
            setSelectedGenreValue(genreValue);

            const moodSelect = document.getElementById("mood-select");
            if (moodSelect) {
              moodSelect.style.display = "block";
            }
          }}
          onMoodSelect={(moodValue: number) => {
            dispatch(whatMoodNow(moodValue));
            setSelectedGenreValue(moodValue);
          }}
        />
      </Padding>
    </>
  );
};
export default Question1WhatMusicNow;
