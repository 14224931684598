import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Dropdown from "../common/components/Dropdown";
import Padding from "../common/components/Padding";
import { useDispatch } from "react-redux";
import { goToNextStep } from "../features/journey";
import Button from "../common/components/Button";

const Welcome = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Center>
        <img
          src="/assets/images/REMIXED logo_white.png"
          alt="REMIXED logo"
          className="logo-image"
        />
      </Center>
      <Padding>
        <Center>
          <div
            className="flower-filter"
            aria-label="A monochromatic purple flower icon with wispy fluffy
            petals. Superimposed on top is a grey rectangular button that
            reads: enter experience"
          >
            <div className="flower-filter-inside">
              <Button
                style={{ width: "fit-content" }}
                onClick={() => {
                  dispatch(goToNextStep());
                }}
              >
                ENTER EXPERIENCE
              </Button>
            </div>
          </div>
        </Center>
      </Padding>
      <VerticalCenter>
        <Center>
          <Dropdown
            title={<h3>About Remixed</h3>}
            content={
              <>
                <p>
                  <em>Remixed</em> is a deep listening experience that gathers
                  true stories of transformation from all over the globe in a
                  meditation on how we instigate change in our lives,
                  communities, and world. <em>Remixed</em> can be experienced
                  in-person at a venue or remotely.
                </p>
                <p>
                  <strong>
                    This site is your portal into the performance.
                  </strong>
                </p>
                <p>
                  During the performance, our algorithm will get to know you:
                  how you feel about change, what you like to listen to, and
                  more. Your personalized playlist will be revealed as the
                  experience unfolds. Listen in to music, narrative prompts, and
                  true stories of change. Everything is chosen just for you.
                </p>
                <p>Let’s listen to the sound of change!</p>
              </>
            }
          />
          <Dropdown
            title={<h3>Know before you go</h3>}
            content={
              <>
                <p>
                  Remixed is approximately 40 minutes long, but it may vary
                  slightly depending on your choices.
                </p>
                <p>
                  Bring a charged smartphone and headphones for use during the
                  performance. Wifi is provided.
                </p>
                <p>
                  Our device guide{" "}
                  <a
                    href="https://docs.google.com/document/d/18KbI3Qhf86txs7qfklolWAXSNtwgUWrZvRILTcYI83c/edit#heading=h.w056bso8q8io"
                    target="_blank"
                  >
                    here
                  </a>{" "}
                  identifies compatible devices and how to set your phone up.
                  It’s easy, but takes a moment or two, especially if you have
                  an older phone.
                </p>
                <p>
                  If you have questions, email{" "}
                  <a href="mailto:info@thisistrophy.com">
                    info@thisistrophy.com
                  </a>{" "}
                  or speak to our team before the show.
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>How to begin</h3>}
            content={
              <p>
                <strong>You will need an access code to enter.</strong> If you
                are experiencing Remixed in-person, our team will share the code
                when you arrive. If you are experiencing Remixed remotely, your
                code will be sent to you in a text message.
              </p>
            }
          />
          <Dropdown
            title={<h3>Accessibility</h3>}
            content={
              <>
                <p>
                  Let’s create the conditions you need to listen and change.
                  Remixed has a set of options to personalize your experience.
                  For example, you have control over text size and contrast,
                  access to alt text for images, and the option of moving around
                  as you need to during the performance.
                </p>
                <p>
                  Learn more about Remixed and our accessibility features in our
                  access guide:{" "}
                  <a
                    href="https://docs.google.com/document/d/1e7b_kLRh4V3EEpUZfgl7Hc40u_I30NFBa7A_SrEB9Qo/edit#heading=h.w056bso8q8io"
                    target="_blank"
                  >
                    click here
                  </a>
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>Who we are</h3>}
            content={
              <>
                <p>
                  Remixed was created by the following artistic team in
                  collaboration with change-makers and community members across
                  Canada and beyond.
                </p>
                <ul>
                  <li>Director and Producer: Sarah Conn</li>
                  <li>Dramaturg and Collaborator: Laurel Green</li>
                  <li>Disability Dramaturg: Shay Elrich</li>
                  <li>Territorial Acknowledgement: Tiffany Joseph</li>
                  <li>
                    In-App and Performance Sound Designer and Collaborator:
                    Nancy Tam
                  </li>
                  <li>Assistant Sound Designer: Charlie Cooper</li>
                  <li>
                    Environmental Installation Concept and Flower Installation
                    Designer: Allison O’Connor
                  </li>
                  <li>Projection & Cloud Designer: Michaela van Beinum</li>
                  <li>Electrical Designer: Michaela van Beinum</li>
                  <li>Antechamber Installation: Roxanne Rainville</li>
                  <li>UI/UX Designer: Véronique Claude</li>
                  <li>Web Developers: Kieran Dunch, Miceal Gallagher</li>
                  <li>Backend Developer: Julien Desautels</li>
                  <li>Decade Music Curation: AL Connors</li>
                  <li>Technical Director: Ira Jordinson</li>
                  <li>
                    Creative Makers: Guillaume Saindon, Alexis Gagnon-Clément,
                    Robert Simoneau, Kris Van Loon, Madison O’Connor, Robert
                    O’Connor
                  </li>
                  <li>UX Consultants: Hosan Lee, christian scott</li>
                </ul>
                <p>
                  Our story contributors are anonymous to support their
                  participation. They live all over the globe, and include
                  community activists, doctors, outreach workers, parents,
                  children, death doulas, artists, CEOs, tarot card readers,
                  apiarists, urban planners, military officers, small busines
                  owners, an anesthesiologist, and several opera singers. They
                  might even include you! We are so grateful for their
                  generosity and courage.
                </p>
                <p>
                  Remixed is being developed thanks to the incredible support of
                  ArtEngine, In the Soil Arts Festival, the undercurrents
                  festival’s under development program, the City of Ottawa,
                  Ontario Arts Council, Canada Council for the Arts, and the
                  Ottawa Seed Library.
                </p>
                <p>
                  Our story gathering partners include the{" "}
                  <a href="https://parkdalefoodcentre.ca" target="_blank">
                    Parkdale Food Centre
                  </a>
                  ,
                  <a href="https://wellspring.ca" target="_blank">
                    Wellspring Cancer Support Centre
                  </a>
                  ,
                  <a href="https://victoriaorangeshirtday.com" target="_blank">
                    Orange Shirt Day Victoria
                  </a>
                  , and{" "}
                  <a href="https://www.centrefornewcomers.ca" target="_blank">
                    Calgary’s Centre for Newcomers
                  </a>
                  .
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>Share your story</h3>}
            content={
              <p>
                If you’d like to contribute a story to our archive, get in touch
                at this link:
                <a
                  target="_blank"
                  href="https://www.thisistrophy.com/copy-of-contact-sample-page"
                >
                  click here
                </a>
              </p>
            }
          />
          <Dropdown
            title={<h3>Planting guide</h3>}
            content={
              <>
                <p>
                  During the experience, you will receive seeds. These delicious
                  microgreens are great for salads, smoothies or garnishes. Give
                  them water and keep in the dark for a few days and they’ll
                  sprout. We are part of ecosystems in constant change, and
                  experiencing change is a way to feel our bodies connecting to
                  these systems.
                </p>
                <p>
                  Share photos of your plants by tagging us on social media{" "}
                  <a
                    href="https://www.instagram.com/thisistrophy/"
                    target="_blank"
                  >
                    @thisistrophy
                  </a>
                  . We love photos of baby plants!
                </p>
              </>
            }
          />
          <Dropdown
            title={<h3>Contact us</h3>}
            content={
              <>
                <p>
                  If you’d like to know more about the show, email us at{" "}
                  <a href="info@thisistrophy.com">info@thisistrophy.com</a>.
                </p>
              </>
            }
          />
        </Center>
      </VerticalCenter>
    </>
  );
};
export default Welcome;
