import Toggle from "react-toggle";
import React, { createRef, useEffect } from "react";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import Switcher from "../common/components/Switcher";
import { useDispatch, useSelector } from "react-redux";
import gsap from "gsap";
import { selectTheme } from "../features/application/select";
import {
  FontSize,
  ThemeType,
  whatTheme,
} from "../features/application/ApplicationReducer";
import FontSlider from "../common/components/FontSlider";

export const FONT_OPTIONS: { label: string; cssVariable: FontSize }[] = [
  {
    label: "Small",
    cssVariable: "font-size-small",
  },
  {
    label: "Medium",
    cssVariable: "font-size-medium",
  },
  {
    label: "Large",
    cssVariable: "font-size-large",
  },
];

type AccessiblityMenuProps = {
  onClose: () => void;
};

const AccessiblityMenu = (props: AccessiblityMenuProps) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const selectedTheme = useSelector(selectTheme);

  const wrapper = createRef<HTMLDivElement>();

  const slideUpSettingsPanel = () => {
    gsap.to(wrapper.current, {
      y: 0,
      duration: 0.4,
      display: "block",
      yoyo: false,
    });
  };

  useEffect(() => {
    slideUpSettingsPanel();
  }, []);

  return (
    <div
      style={{
        height: "90px",
        display: "none",
        transform: "translateY(1000px)",
        borderTop: "8px solid var(--surface-4)",
        borderTopStyle: "ridge",
      }}
      ref={wrapper}
    >
      <Padding>
        <Stack>
          <img
            role="button"
            onClick={onClose}
            style={{ width: "2rem", filter: "invert(1)" }}
            src="/assets/images/cross.png"
            alt="logo"
          />
          <h1>Settings</h1>
          <h2>Font Size</h2>
          <FontSlider />
          <hr />
          <Switcher justifyContent="space-between" alignItems="start">
            <h2>Light mode:</h2>
            <Toggle
              defaultChecked={selectedTheme === "Light"}
              icons={false}
              className="toggle"
              onChange={(e) => {
                dispatch(
                  whatTheme(e.target.checked ? "Light" : ("Dark" as ThemeType))
                );
              }}
            ></Toggle>
          </Switcher>
        </Stack>
      </Padding>
    </div>
  );
};

export default AccessiblityMenu;
