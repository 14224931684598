import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";

export default function DurationPrompt() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <img src="assets/images/5_Clock.png" alt={t("Clock")} width="40%" />
        <p>{t("We're going to be together for about 45 minutes.")}</p>
        <p>
          {t(
            "There are so many distractions these days. Please try to stay with us as best you can."
          )}
        </p>
        <p>{t("We're grateful you're here.")}</p>
      </Center>
    </VerticalCenter>
  );
}
