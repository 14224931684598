import { BASE_URL } from "./BaseProxy";

const PASSWORD_URL = `${BASE_URL}/showtime/`;

export const sendPasscode = async (passcode: string) => {
  const formData = new FormData();
  formData.append("passcode", passcode);

  return await fetch(PASSWORD_URL, {
    method: "POST",
    body: formData,
    mode: "cors",
  })
    .then((response: Response) => {
      return response.json();
    })
    .then((data: PasscodeResponse) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export interface PasscodeResponse {
  error: string;
  result: string;
  showID: string;
}
