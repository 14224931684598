import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";

import { RootState } from "../app/Store";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import { selectListenerProfile } from "../features/listenerProfile/select";

const storyChoiceText = [
  "You are most interested in making systemic change. What if your own story could give you the agency to do so?",
  "You are most interested in making systemic change. What if the agency to do so starts in your community?",
  "You are most interested in making a change that is both personal and systemic. What if you have to put a part of yourself on the line in order to do so?",
  "You are most interested in making personal change. What if your ability to do so challenges your values?",
  "You are most interested in making personal change. What if you don't feel you have the agency to do so?",
];

const Scene1 = () => {
  const state = useStore().getState() as RootState;
  const listenerProfile = selectListenerProfile(state);
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h2>
            {t(
              `storyChoiceOptions.${
                storyChoiceText[listenerProfile.changeType - 1]
              }`
            )}
          </h2>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};
export default Scene1;
