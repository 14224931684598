import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import Spacer from "../common/components/Spacer";

const Goodbye = () => {
  const { t } = useTranslation();

  return (
    <>
      <Center>
        <Spacer />
        <Padding>
          <h2>{t("Goodbye!")}</h2>
        </Padding>
      </Center>
    </>
  );
};
export default Goodbye;
