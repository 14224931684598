import { Era } from "../EraGenreMood";

const _2020s: Era = {
  era: "2020s",
  value: 8,
  genres: [
    {
      value: 1,
      genre: "Dance Pop",
      moods: [
        { value: 1, mood: "Banger" },
        { value: 2, mood: "Groove" },
        { value: 3, mood: "Life is Good" },
      ],
    },
    {
      value: 2,
      genre: "Feels",
      moods: [
        { value: 1, mood: "Bliss" },
        { value: 2, mood: "Regret" },
        { value: 3, mood: "Stillness" },
      ],
    },
    {
      value: 3,
      genre: "Guitars",
      moods: [
        { value: 1, mood: "Breezy" },
        { value: 2, mood: "Hopeful" },
        { value: 3, mood: "Introspective" },
      ],
    },
    {
      value: 4,
      genre: "Hip Hop",
      moods: [
        { value: 1, mood: "Club" },
        { value: 2, mood: "Cruisin'" },
        { value: 3, mood: "Remix" },
      ],
    },
    {
      value: 5,
      genre: "Synth",
      moods: [
        { value: 1, mood: "Driving" },
        { value: 2, mood: "Funk" },
        { value: 3, mood: "Deep" },
      ],
    },
    {
      value: 6,
      genre: "Country",
      moods: [
        { value: 1, mood: "Happiness" },
        { value: 2, mood: "Playful" },
        { value: 3, mood: "No Regrets" },
      ],
    },
  ],
};

export default _2020s;
