import { Era } from "../EraGenreMood";

const _2010s: Era = {
  era: "2010s",
  value: 7,
  genres: [
    {
      value: 1,
      genre: "Hip Hop",
      moods: [
        { value: 1, mood: "Smooth" },
        { value: 2, mood: "Loud" },
        { value: 3, mood: "Work" },
      ],
    },
    {
      value: 2,
      genre: "Dance Pop",
      moods: [
        { value: 1, mood: "Party" },
        { value: 2, mood: "Forward" },
        { value: 3, mood: "Serious" },
      ],
    },
    {
      value: 3,
      genre: "Reggae/Latin",
      moods: [
        { value: 1, mood: "Party" },
        { value: 2, mood: "Sexy" },
        { value: 3, mood: "Electro" },
      ],
    },
    {
      value: 4,
      genre: "Pop",
      moods: [
        { value: 1, mood: "The Future" },
        { value: 2, mood: "Dark" },
        { value: 3, mood: "Quirky" },
      ],
    },
    {
      value: 5,
      genre: "Guitars",
      moods: [
        { value: 1, mood: "Cabin" },
        { value: 2, mood: "Campfire" },
        { value: 3, mood: "Stompin'" },
      ],
    },
    {
      value: 6,
      genre: "Country",
      moods: [
        { value: 1, mood: "Drinkin'" },
        { value: 2, mood: "Crush" },
        { value: 3, mood: "Remix" },
      ],
    },
  ],
};

export default _2010s;
