import { useTranslation } from "react-i18next";
import Padding from "../common/components/Padding";
import Switcher from "../common/components/Switcher";
import VerticalCenter from "../common/components/VerticalCenter";

export default function ReminderPrompt() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Switcher>
          <img
            width="60px"
            src="assets/images/6.1_Box_closed.png"
            alt="Closed box"
          />
          <p>{t("Do you have the box I sent you nearby?")}</p>
        </Switcher>
      </Padding>
      <Padding>
        <Switcher>
          <p>{t("You'll also need a glass of water.")}</p>
          <img
            width="60px"
            src="assets/images/6.2_Water.png"
            alt="Glass of water"
          />
        </Switcher>
      </Padding>
      <Padding>
        <Switcher>
          <img width="60px" src="assets/images/6.3_Tea.png" alt="Cup of tea" />
          <p>
            {t(
              "This is your chance to settle in. Do you have everything you need?"
            )}
          </p>
        </Switcher>
      </Padding>
    </VerticalCenter>
  );
}
