import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Padding from "../common/components/Padding";
import { formativePeriod } from "../features/listenerProfile";
import Center from "../common/components/Center";
import PillMultiSelect from "../common/components/PillMultiSelect";

const PERIODS = [
  "Childhood",
  "Adolescence",
  "Early Adulthood (20-30)",
  "Adulthood (30-65)",
  "Older Adulthood (65+)",
];

export default function Question2FormativePeriod() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("Which period(s) of your life shaped you the most?")}</h1>
        </Center>
        <Center>
          <Padding>
            <pre>{t("SELECT AS MANY AS APPLY")}</pre>
          </Padding>
        </Center>
      </Padding>
      <Padding>
        <PillMultiSelect
          options={PERIODS.map((text) => text.toUpperCase())}
          onClick={(valuesList) => dispatch(formativePeriod(valuesList))}
        />
      </Padding>
    </>
  );
}
