import { useTranslation } from "react-i18next";
import Padding from "../common/components/Padding";
import Switcher from "../common/components/Switcher";
import VerticalCenter from "../common/components/VerticalCenter";

export default function Planting2() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Switcher>
          <img
            width="60px"
            src="assets/images/40.1_Open pod.png"
            alt={t("Open pod")}
          />
          <p>{t("Break open your pod")}</p>
        </Switcher>
      </Padding>
      <Padding>
        <Switcher>
          <p>{t("Place the contents on the cloth")}</p>
          <img
            width="60px"
            src="assets/images/40.2_Place seeds.png"
            alt={t("Place seeds in pod")}
          />
        </Switcher>
      </Padding>
    </VerticalCenter>
  );
}
