import { Era } from "../EraGenreMood";

const _80s: Era = {
  era: "80s",
  value: 4,
  genres: [
    {
      value: 1,
      genre: "Country",
      moods: [
        { value: 1, mood: "Sentimental" },
        { value: 2, mood: "Dancing" },
        { value: 3, mood: "Bop" },
      ],
    },
    {
      value: 2,
      genre: "New Wave",
      moods: [
        { value: 1, mood: "Late Night" },
        { value: 2, mood: "Bop" },
        { value: 3, mood: "Dreams" },
      ],
    },
    {
      value: 3,
      genre: "Arena Rock",
      moods: [
        { value: 1, mood: "Hair" },
        { value: 2, mood: "Sexy" },
        { value: 3, mood: "Fantasy" },
      ],
    },
    {
      value: 4,
      genre: "Hip Hop",
      moods: [
        { value: 1, mood: "DJs" },
        { value: 2, mood: "Dancing" },
        { value: 3, mood: "Youth" },
      ],
    },
    {
      value: 5,
      genre: "Pop",
      moods: [
        { value: 1, mood: "Belonging" },
        { value: 2, mood: "Party" },
        { value: 3, mood: "Bittersweet" },
      ],
    },
    {
      value: 6,
      genre: "Metal",
      moods: [
        { value: 1, mood: "Rebel" },
        { value: 2, mood: "Dark" },
        { value: 3, mood: "Wild" },
      ],
    },
  ],
};

export default _80s;
