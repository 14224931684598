import { useTranslation } from "react-i18next";

import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Stack from "../common/components/Stack";

const Scene3Intro = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <Stack>
          <h2>{t("Thank you!")}</h2>
          <h2>{t("We will now prepare your last tracks.")}</h2>
        </Stack>
      </Center>
    </VerticalCenter>
  );
};

export default Scene3Intro;
