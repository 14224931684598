import { useTranslation } from "react-i18next";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import Center from "../common/components/Center";
import Stack from "../common/components/Stack";
import CanYouHearAudioYesNo from "./CanYouHearAudioYesNo";

const CanYouHearAudioFix2 = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <Stack>
            <h1>{t("Increase the audio volume on your device")}</h1>
            <h2>{t("Do you hear audio?")}</h2>
          </Stack>
        </Center>
      </Padding>
      <CanYouHearAudioYesNo />
    </VerticalCenter>
  );
};

export default CanYouHearAudioFix2;
