import React from "react";

export type justifyContentType =
  | "start"
  | "center"
  | "end"
  | "space-around"
  | "space-between";

interface SwitcherProps {
  children?: React.ReactNode[];
  justifyContent?: justifyContentType;
  alignItems?: "center" | "start" | "end";
}

const Switcher = (props: SwitcherProps) => {
  return (
    <div
      className="switcher"
      style={{
        justifyContent: props.justifyContent ? props.justifyContent : "start",
        alignItems: props.alignItems ? props.alignItems : "center",
      }}
    >
      {props.children && props.children.map((child) => <div>{child}</div>)}
    </div>
  );
};
export default Switcher;
