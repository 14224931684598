import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import { sendPasscode, PasscodeResponse } from "../features/PasscodeProxy";
import { goToNextStep } from "../features/journey";
import { whatShowId, setJourneyType } from "../features/listenerProfile";
import { isValidUser } from "../features/questionnaire";
import Button from "../common/components/Button";

const EnterCode = () => {
  const [code, setCode] = useState<string>("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<string>("");

  const processLoginAttempt = (response: PasscodeResponse) => {
    const SHOW_DOESNT_EXIST = "no shows with that id";

    const tzOffsetInMs = new Date().getTimezoneOffset() * 60000;
    const localISOTime = new Date(Date.now() - tzOffsetInMs).toISOString();
    const currentTimeInMs = Date.parse(localISOTime);

    const responseDateInMs = Date.parse(response.result);
    const journeyType = code[0] === "1" ? "IN_PERSON" : "AT_HOME";

    const isValidCode =
      response.result.toLocaleLowerCase() !== SHOW_DOESNT_EXIST;
    const isTooEarly = currentTimeInMs < responseDateInMs;
    const isOnTime = currentTimeInMs >= responseDateInMs;

    if (!isValidCode) {
      setError(
        t("Oops! You may have entered the wrong code. Please try again.")
      );
    } else if (isTooEarly) {
      setError(
        t("Wow, you sure are here early! Come back closer to showtime.")
      );
    } else if (isOnTime) {
      dispatch(whatShowId(response.showID));
      dispatch(isValidUser(true));
      dispatch(setJourneyType(journeyType));
      dispatch(goToNextStep());
    } else {
      setError(t("Something went wrong. Please contact the Remixed team!"));
    }
  };

  const validatePasscode = async (passcode: string) => {
    setError("");

    if (passcode.length === 6) {
      const response: PasscodeResponse = await sendPasscode(passcode);
      processLoginAttempt(response);
    } else {
      setError(t("Your passcode is too short."));
    }
  };

  return (
    <VerticalCenter>
      <Padding>
        <div
          className="flower-filter"
          aria-label="A monochromatic purple flower icon with wispy fluffy
            petals. Superimposed on top is a grey rectangular button that
            reads: enter experience"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="flower-filter-input">
            <input
              className="code-input"
              type="tel"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              maxLength={6}
              placeholder="Enter Code"
            />
          </div>
        </div>
        <Center>
          <p>{error}</p>
          <Button
            onClick={() => {
              validatePasscode(code);
            }}
          >
            {t("Let's Begin!")}
          </Button>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};
export default EnterCode;
