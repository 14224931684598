type StackProps = {
  children?: React.ReactNode;
  align?: "center";
};

const Stack = (props: StackProps) => {
  return (
    <div style={{ alignItems: `${props.align}` }} className="stack">
      {props.children}
    </div>
  );
};
export default Stack;
