import _1950sAndEarlier from "./_1950sAndEarlier";
import _60s from "./_60s";
import _70s from "./_70s";
import _80s from "./_80s";
import _90s from "./_90s";
import _2000s from "./_2000s";
import _2010s from "./_2010s";
import _2020s from "./_2020s";

export { default as _1950sAndEarlier } from "./_1950sAndEarlier";
export { default as _60s } from "./_60s";
export { default as _70s } from "./_70s";
export { default as _80s } from "./_80s";
export { default as _90s } from "./_90s";
export { default as _2000s } from "./_2000s";
export { default as _2010s } from "./_2010s";
export { default as _2020s } from "./_2020s";

export const getAll = () => [
  _1950sAndEarlier,
  _60s,
  _70s,
  _80s,
  _90s,
  _2000s,
  _2010s,
  _2020s,
];
