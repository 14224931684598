import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";

const Question1PlaylistBeingCreated = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h2>
            {t(
              "Your playlist is being created based on your answers, except for the first story which is based on a single question."
            )}
          </h2>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};
export default Question1PlaylistBeingCreated;
