import {
  all,
  take,
  call,
  put,
  select,
  takeEvery,
  cancelled,
} from "@redux-saga/core/effects";
import { END, eventChannel } from "redux-saga";
import { AudioName, State as AudioLibraryState } from ".";
import { goToNextStep, startJourney, State as JourneyState } from "../journey";
import getPath, { Step } from "../journey/paths/Paths";
import { selectJourney } from "../journey/select";

import {
  audioLoadError,
  loadAudio,
  readyToPlayAudio,
  resumeLibrary,
  retrivedFirstQuestionnaireAudioSources,
  retrivedSecondQuestionnaireAudioSources,
  retrivedThirdQuestionnaireAudioSources,
  retrievedIntroAudioSources,
} from "./AudioLibraryReducer";
import { selectAudioLibrary } from "./select";
import {
  downloadAudio,
  getAudio,
  WebAudio,
  WebAudioEvent,
} from "./WebAudioAPI";

const MAX_CONCURRENT_DOWNLOADS = 1;

function* loadAudioSources() {
  const journey: JourneyState = yield select((state) => selectJourney(state));
  const audioLibrary: AudioLibraryState = yield select((state) =>
    selectAudioLibrary(state)
  );

  const { pathType, stepIndex } = journey;

  const loadingStrategy = determineAudioLoadingStrategy();

  const thePathAhead =
    loadingStrategy === "ALL_STEP_AUDIO"
      ? getPath(pathType).steps.slice(stepIndex)
      : getPath(pathType).steps.slice(stepIndex, stepIndex + 1);

  const currentlyLoadingCount = thePathAhead.reduce((loadingCount, step) => {
    if (!step) return loadingCount;
    if (!step.acoustics || Object.keys(step.acoustics).length === 0)
      return loadingCount;

    return (
      Object.keys(step.acoustics).filter(
        (audioName) => audioLibrary[audioName as AudioName].status === "LOADING"
      ).length + loadingCount
    );
  }, 0);

  if (currentlyLoadingCount < MAX_CONCURRENT_DOWNLOADS) {
    const audioLoadPriority = thePathAhead.reduce<AudioName[]>(
      (audioPriority: AudioName[], step: Step): AudioName[] => {
        if (!step) return audioPriority;
        if (!step.acoustics || Object.keys(step.acoustics).length === 0)
          return audioPriority;

        const notLoadedAudio = (
          Object.keys(step.acoustics) as Array<AudioName>
        ).filter(
          (audioName) =>
            audioLibrary[audioName as AudioName].status === "NOT_LOADED"
        );

        return [...audioPriority, ...notLoadedAudio];
      },
      <AudioName[]>[]
    );

    const weHaveUnloadedAudio =
      Object.keys(audioLibrary)
        .map((audioName: string) => audioLibrary[audioName as AudioName].status)
        .includes("NOT_LOADED") && audioLoadPriority.length > 0;

    if (weHaveUnloadedAudio) {
      const nextAudioNameToLoad = audioLoadPriority[0];
      const { sourceUrl } = audioLibrary[nextAudioNameToLoad];

      downloadAudio(sourceUrl);

      yield put(loadAudio(nextAudioNameToLoad));
      yield monitorWebAudioLoadEvents(sourceUrl, nextAudioNameToLoad);
    }
  }
}

function attachLoadEventHandlers(webAudio: WebAudio) {
  return eventChannel((emitter) => {
    webAudio.onLoad(() => {
      emitter("LOAD");
      emitter(END);
    });
    webAudio.onLoadError((e) => {
      emitter("LOAD_ERROR");
      emitter(END);
    });

    return () => {
      return;
    };
  });
}

function* monitorWebAudioLoadEvents(
  sourceUrl: string,
  audioName: AudioName
): unknown {
  const webAudio = getAudio(sourceUrl);
  if (webAudio) {
    const eventEmitter = yield call(attachLoadEventHandlers, webAudio);

    try {
      while (true) {
        const event: WebAudioEvent = yield take(eventEmitter);

        switch (event) {
          case "LOAD":
            yield put(readyToPlayAudio(audioName));
            break;
          case "LOAD_ERROR":
            yield put(
              audioLoadError({
                audioName,
                message: "We encountered a problem trying to load the audio",
              })
            );
            break;
        }
      }
    } finally {
      if (yield cancelled()) {
        eventEmitter.close();
      }
    }
  }
}

type AudioDownloadStrategy = "ALL_STEP_AUDIO" | "NEXT_STEP_AUDIO";
const determineAudioLoadingStrategy = (): AudioDownloadStrategy => {
  // In the future we may want to have a dynamic way to determine
  // a download strategy on a per device basis
  return "NEXT_STEP_AUDIO";
};

export function* reactions(): unknown {
  yield all([
    yield takeEvery(retrievedIntroAudioSources.type, loadAudioSources),
    yield takeEvery(
      retrivedFirstQuestionnaireAudioSources.type,
      loadAudioSources
    ),
    yield takeEvery(
      retrivedSecondQuestionnaireAudioSources.type,
      loadAudioSources
    ),
    yield takeEvery(
      retrivedThirdQuestionnaireAudioSources.type,
      loadAudioSources
    ),
    yield takeEvery(goToNextStep.type, loadAudioSources),
    yield takeEvery(startJourney.type, loadAudioSources),
    yield takeEvery(readyToPlayAudio.type, loadAudioSources),
    yield takeEvery(resumeLibrary.type, loadAudioSources),
    yield takeEvery(loadAudio.type, loadAudioSources),
  ]);
  // }
}
