import { Howler } from "howler";

export interface Sound {
  _id: number;
  _rate: number;
  _stop: number;
  _parent: {
    _duration: number;
    fade: (from: number, to: number, duration: number) => void;
    on: (event: string, fun: (e: any) => void) => void;
    playing: () => boolean;
    stop: () => void;
  };
}

export interface HowlPrivate {
  _src: string;
  _sounds: Sound[];
  play: () => void;
  playing: () => boolean;
  volume: (vol?: number) => number;
  unload: () => void;
}

// @ts-ignore We are using the private Howler API which is not typed
const howls = (): HowlPrivate[] => Howler._howls;

export const howlFromId = (id: number): HowlPrivate => {
  return howls().filter((howl: HowlPrivate) => {
    const hasSound = howl._sounds.length === 1;
    const matchesId = hasSound && howl._sounds[0]._id === id;

    return matchesId;
  })[0];
};

export const howlFromSource = (src: string): HowlPrivate => {
  return howls().filter((howl: HowlPrivate) => {
    const hasSound = howl._sounds.length === 1;
    return hasSound && howl._src === src;
  })[0];
};
