import React, { useState } from "react";

type DropdownProps = {
  title: React.ReactNode;
  content: React.ReactNode;
};

const Dropdown = (props: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className="dropdown-title"
        onClick={(e) => {
          setIsOpen(!isOpen);
          e.currentTarget.classList.toggle("clicked");
        }}
      >
        {props.title}
        <p style={{ cursor: "pointer" }}>+</p>
      </div>
      {isOpen && <div className="dropdown-content">{props.content}</div>}
    </>
  );
};

export default Dropdown;
