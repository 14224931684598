import { useRef, useEffect } from "react";
import { createNoise2D } from "simplex-noise";

const Waveform: React.FC<{ color: string; value: number; title: string }> = (
  props
) => {
  // const color1 = useContext(ContextProfile).profileResult.color1;
  const pathRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    const value = props.value - 1;
    const simplexNoise = createNoise2D(Math.random);
    const width = 150;
    const xs: number[] = [];
    for (let i = 0; i <= width; i++) {
      xs.push(i);
    }
    let t = 0;

    const animate = () => {
      let counter = 0;
      let i = +1;

      const points = xs.map((x) => {
        const y: number[] = [
          45 + simplexNoise(x / 100, (i + t) / 100) * (i * 15),
          45 + simplexNoise(x / 60, (i + t) / 80) * (i * 25),
          45 + simplexNoise(x / 50, (i + t) / 70) * (i * 30),
          45 + simplexNoise(x / 30, (i + t) / 50) * (i * 35),
          45 + simplexNoise(x / 20, (i + t) / 30) * (i * 40),
        ];

        // let y = (width - width / 2 + simplexNoise.noise2D(x / 100, (i + t) / 20) * (i*20) );
        if (counter === width) i = -1;
        if (counter === -width) i = +1;
        counter += i;
        return [x, y[value], i];
      });

      const path =
        "M" +
        points
          .map((p) => {
            return p[0] + 50 + "," + p[1];
          })
          .join(" L");

      // document.querySelector("path")?.setAttribute("d", path);
      if (pathRef.current) {
        (pathRef.current as SVGPathElement).setAttribute("d", path);
        t += 0.25;
        requestAnimationFrame(animate);
      }
    };
    animate();
  }, [props.value]);

  return (
    <svg
      className="waveform"
      preserveAspectRatio="xMidYMid meet"
      viewBox="25 0 200 100"
      width="200"
      height="100"
    >
      <path d="M10,10 L100,100" stroke={props.color} ref={pathRef}></path>
    </svg>
  );
};

export default Waveform;
