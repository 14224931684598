import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { RootState } from "../app/Store";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import { selectSoothingSound } from "../features/listenerProfile/select";
import AudioFlower from "../common/components/AudioFlower";

const MUSIC_TEXT = [
  "I know the crackles of a campfire are soothing for you",
  "I know the buzz of coffee shops is soothing for you",
  "I know the whirr of a fan is soothing for you",
  "I know the sounds of the forest are soothing for you",
  "I know the sound of rain is soothing for you",
  "I know the sound of evening streets is soothing for you",
  "I know mechanical hums are soothing for you",
  "I know the sound of waves is soothing for you",
  "I know white noise is soothing for you",
  "I know the sound of wind is soothing for you	",
];

const SoothingSong = () => {
  const state = useStore().getState() as RootState;
  const soothingSound = selectSoothingSound(state);
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <AudioFlower animate />
      </Center>
      <Padding>
        <Center>
          <h2>{t(MUSIC_TEXT[soothingSound - 1])}</h2>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};
export default SoothingSong;
