import LandAcknowledgement from "../../../components/LandAcknowledgement";
import Question1WhatColor from "../../../components/Question1WhatColor";
import Question1WhatEnneagram from "../../../components/Question1WhatEnneagram";
import Question1WhatMusicYoung from "../../../components/Question1WhatMusicYoung";
import Question1WhatSoothingSound from "../../../components/Question1WhatSoothingSound";
import Question1WhatMusicNow from "../../../components/Question1WhatMusicNow";
import Question1End from "../../../components/Question1End";
import Question1ExcludeTopics from "../../../components/Question1ExcludeTopics";
import Question1PlaylistBeingCreated from "../../../components/Question1PlaylistBeingCreated";
import Question1WhatKindOfChange from "../../../components/Question1WhatKindOfChange";
import Scene1 from "../../../components/Scene1";
import {
  completedQuestionnaireOne,
  completedQuestionnaireTwo,
  completedQuestionnaireThree,
} from "../../questionnaire";
import Question2Intro from "../../../components/Question2Intro";
import Question2HowChangeFeel from "../../../components/Question2HowChangeFeel";
import Question2MakeChange from "../../../components/Question2MakeChange";
import Question2HowMuchChange from "../../../components/Question2HowMuchChange";
import Question2FormativePeriod from "../../../components/Question2FormativePeriod";
import Question2WantThreeThings from "../../../components/Question2WantThreeThings";
import Question2HateThreeThings from "../../../components/Question2HateThreeThings";
import Question2MostAfraid from "../../../components/Question2MostAfraid";
import Question2Dependent from "../../../components/Question2Dependent";
import Question2Ignored from "../../../components/Question2Ignored";
import Question2IdentityIndependence from "../../../components/Question2IdentityIndependence";
import Question2Relationships from "../../../components/Question2Relationships";
import Question2Family from "../../../components/Question2Family";
import Scene2Intro from "../../../components/Scene2Intro";
import Planting2 from "../../../components/Planting2";
import Question3Intro from "../../../components/Question3Intro";
import Question3InternalExternal from "../../../components/Question3InternalExternal";
import Question3WantMore from "../../../components/Question3WantMore";
import Question3ActionsValues from "../../../components/Question3ActionsValues";
import Question3Pressing from "../../../components/Question3Pressing";
import Question3Impact from "../../../components/Question3Impact";
import Question3LifeWillBe from "../../../components/Question3LifeWillBe";
import Question3Afraid from "../../../components/Question3Afraid";
import Scene3Intro from "../../../components/Scene3Intro";
import Scene3Story1 from "../../../components/Scene3Story1";
import Scene3Narration from "../../../components/Scene3Narration";
import WordOfEncouragement from "../../../components/WordOfEncouragement";
import SendWord from "../../../components/SendWord";
import Planting3 from "../../../components/Planting3";
import ReceivedWord from "../../../components/ReceivedWord";
import Feedback from "../../../components/Feedback";
import NiceSpendingTimeWithYou from "../../../components/NiceSpendingTimeWithYou";
import HeadphonePrompt from "../../../components/HeadphonePrompt";
import DurationPrompt from "../../../components/DurationPrompt";
import ReminderPrompt from "../../../components/ReminderPrompt";
import GuidePrompt from "../../../components/GuidePrompt";
import SettleIn from "../../../components/SettleIn";
import Scene2Story2 from "../../../components/Scene2Story2";
import Scene2Story1 from "../../../components/Scene2Story1";
import Scene2Narration1 from "../../../components/Scene2Narration1";
import OldAudio from "../../../components/OldAudio";

import { Step } from "./Paths";
import PlantPellet from "../../../components/PlantPellet";
import Goodbye from "../../../components/GoodBye";
import PreparingYourFirstAudio from "../../../components/PreparingYourFirstAudio";
import { makeAcousticConfig } from "./PathDefinitions";

export const getAtHomePathDefinition = (): Step[] => [
  {
    component: <HeadphonePrompt />,
    name: "Headphone prompt",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_LEFT",
    acoustics: {
      claireDeLune: makeAcousticConfig(),
    },
  },
  {
    component: <LandAcknowledgement />,
    name: "Land Acknowledgement",
    allowNext: { allowNextOn: "MANUAL_CLICK" },

    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <DurationPrompt />,
    name: "Duration prompt",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <ReminderPrompt />,
    name: "Reminder prompt",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <GuidePrompt />,
    name: "Guide prompt",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <SettleIn />,
    name: "Settle in",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "introNarration" },
    },
    acoustics: {
      introNarration: makeAcousticConfig(),
      claireDeLune: makeAcousticConfig({ secondsUntilFadeOut: 0.1 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question1WhatColor />,
    name: "Q1. What color",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
    acoustics: {
      claireDeLune: makeAcousticConfig(),
    },
  },
  {
    component: <Question1WhatEnneagram />,
    name: "Q1. What enegram",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatMusicYoung />,
    name: "Q1. What music young",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatSoothingSound />,
    name: "Q1. What soothing sound",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatMusicNow />,
    name: "Q1. What music now",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1End />,
    name: "Q1. End",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1ExcludeTopics />,
    name: "Q1. Exclude topics",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1PlaylistBeingCreated />,
    name: "Q1. Playlist being created",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatKindOfChange />,
    name: "Q1. What kind of change",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <PreparingYourFirstAudio />,
    name: "Preparing your first tracks",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
    acoustics: {
      claireDeLune: makeAcousticConfig(),
      soothingTrack: makeAcousticConfig({ volume: 0.2, fadeInSeconds: 6 }),
    },
    dispatchOnEnter: (state, dispatch) => {
      if (state.questionnaire.questionnaireOne === "INCOMPLETE") {
        dispatch(completedQuestionnaireOne());
      }
    },
  },
  {
    component: <OldAudio />,
    name: "Young audio",
    allowNext: { allowNextOn: "COUNTDOWN", config: { secondsToNextStep: 60 } },
    acoustics: {
      oldSong: makeAcousticConfig({ secondsUntilFadeOut: 50 }),
      claireDeLune: makeAcousticConfig({ secondsUntilFadeOut: 0.001 }),
      soothingTrack: makeAcousticConfig({
        volume: 0.2,
        secondsUntilFadeOut: 0.001,
      }),
    },

    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Scene1 />,
    name: "S1. Intro",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: { storySelectionOne: makeAcousticConfig() },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <PlantPellet />,
    name: "Planet Pellet",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 30,
      },
    },
    acoustics: {
      soothingTrack: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question2Intro />,
    name: "Q2. Intro",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    acoustics: {
      soothingTrack: makeAcousticConfig({ volume: 0.8 }),
      claireDeLune: makeAcousticConfig({ volume: 0.4, fadeInSeconds: 10 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question2HowChangeFeel />,
    name: "Q2. How change feel",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2MakeChange />,
    name: "Q2. Make change",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2HowMuchChange />,
    name: "Q2. How much change",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2FormativePeriod />,
    name: "Q2. Formative period",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2WantThreeThings />,
    name: "Q2. Want three things",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2HateThreeThings />,
    name: "Q2. Hate three things",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2MostAfraid />,
    name: "Q2. Most afraid",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Dependent />,
    name: "Q2. Dependent",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Ignored />,
    name: "Q2. Ignored",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2IdentityIndependence />,
    name: "Q2. Identity & independence",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Relationships />,
    name: "Q2. Relationships",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Family />,
    name: "Q2. Family",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Scene2Intro />,
    name: "S2. Intro",
    dispatchOnEnter: (state, dispatch) => {
      if (state.questionnaire.questionnaireTwo === "INCOMPLETE") {
        dispatch(completedQuestionnaireTwo());
      }
    },

    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Scene2Story1 />,
    name: "S2. Story one",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "story1" },
    },
    acoustics: {
      story1: makeAcousticConfig(),
      soothingTrack: makeAcousticConfig({
        volume: 0.2,
        secondsUntilFadeOut: 0.001,
      }),
      claireDeLune: makeAcousticConfig({ secondsUntilFadeOut: 0.001 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Scene2Narration1 />,
    name: "S2. Narration",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: {
      sceneTwoNarration: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Scene2Story2 />,
    name: "S2. Story two",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: { story2: makeAcousticConfig() },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Planting2 />,
    name: "Planting 2",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 60,
      },
    },
    acoustics: {
      changeSong: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question3Intro />,
    name: "Q3. Intro",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    acoustics: {
      soothingTrack: makeAcousticConfig({ volume: 0.8 }),
      claireDeLune: makeAcousticConfig({ volume: 0.4, fadeInSeconds: 10 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question3InternalExternal />,
    name: "Q3. Internal external",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3WantMore />,
    name: "Q3. Want more",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3ActionsValues />,
    name: "Q3. Actions values",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3Pressing />,
    name: "Q3. Pressing",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3Impact />,
    name: "Q3. Impact",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3LifeWillBe />,
    name: "Q3. Life will be",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3Afraid />,
    name: "Q3. Afraid",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Scene3Intro />,
    name: "S3. Intro",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    dispatchOnEnter: (state, dispatch) => {
      if (state.questionnaire.questionnaireThree === "INCOMPLETE") {
        dispatch(completedQuestionnaireThree());
      }
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Scene3Story1 />,
    name: "S3. Story 1",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "changeStory" },
    },
    acoustics: {
      soothingTrack: makeAcousticConfig({
        volume: 0.8,
        secondsUntilFadeOut: 0.001,
      }),
      claireDeLune: makeAcousticConfig({
        volume: 0.4,
        secondsUntilFadeOut: 0.001,
      }),
      changeStory: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Scene3Narration />,
    name: "S3. Narration",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 85,
      },
    },
    acoustics: {
      lastNarration: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <WordOfEncouragement />,
    name: "Words of encouragement",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 30,
      },
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <SendWord />,
    name: "Send words",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: {
        audioCompletion: "lastNarration",
      },
    },
    acoustics: {
      lastNarration: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Planting3 />,
    name: "Planting 3",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 60,
      },
    },
    acoustics: {
      newMusic: makeAcousticConfig({ fadeInSeconds: 15 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <ReceivedWord />,
    name: "Received word",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 30,
      },
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <NiceSpendingTimeWithYou />,
    name: "Nice spending time with you",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: {
        audioCompletion: "newMusic",
      },
    },
    acoustics: {
      newMusic: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Goodbye />,
    name: "Goodbye",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 5,
      },
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Feedback />,
    name: "Feedback",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 30,
      },
    },
    onExitAnimation: "SLIDE_LEFT",
  },
];
