import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./RootSaga";
import JourneyReducer from "../features/journey/JourneyReducer";
import applicationReducer from "../features/application/ApplicationReducer";
import ListenerProfileReducer from "../features/listenerProfile/ListenerProfileReducer";
import QuestionnaireReducer from "../features/questionnaire/QuestionnaireReducer";
import AudioLibraryReducer from "../features/acoustics/AudioLibraryReducer";

import LogRocket from "logrocket";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("cydcw3/remixed");
}

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    application: applicationReducer,
    journey: JourneyReducer,
    listenerProfile: ListenerProfileReducer,
    questionnaire: QuestionnaireReducer,
    audioLibrary: AudioLibraryReducer,
  },
  // LogRocket must always be the last middleware specifed
  middleware: [sagaMiddleware, LogRocket.reduxMiddleware()],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
