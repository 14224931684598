import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import AudioFlower from "../common/components/AudioFlower";

export default function JewelLight2() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <AudioFlower animate opacity={0.4} />
      </Center>
      <Padding>
        <Center>
          <h2>
            {t(
              "Let's listen to this song and imagine the possibilities together."
            )}
          </h2>
        </Center>
      </Padding>
    </VerticalCenter>
  );
}
