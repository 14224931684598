import { useTranslation } from "react-i18next";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import Center from "../common/components/Center";
import Stack from "../common/components/Stack";

const CanYouHearAudioFix3 = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <Stack>
            <h1>{t("Please talk with a Remixed team member")}</h1>
          </Stack>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};

export default CanYouHearAudioFix3;
