import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { RootState } from "../app/Store";
import AudioPlaying from "../common/components/AudioPlaying";
import VerticalCenter from "../common/components/VerticalCenter";
import { selectEraYoung } from "../features/listenerProfile/select";

const musicText = [
  "I know you're hip to music from the 1950s and earlier too. Let's listen in!",
  "I know you dig music from the 60s too. Let's listen in!",
  "I know you groove to music from the 70s too. Let's listen in!",
  "I know you like music from the 80s too. Word. Let's listen in.",
  "I know you've chillaxed to music from the 90s too. Let's listen in!",
  "I know you like music from the 2000s too. That's hot. Let's listen in!",
  "I know you heart music from the 2010s too. Let's listen in!",
  "I know you stan music from the 2020s too. Let's listen in!",
];

const OldAudio = () => {
  const state = useStore().getState() as RootState;
  const eraYoung = selectEraYoung(state);
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <AudioPlaying
        title={t(`musicTextOptions.${musicText[eraYoung - 1]}`)}
        subtitle=""
      />
    </VerticalCenter>
  );
};
export default OldAudio;
