import { useTranslation } from "react-i18next";
import Padding from "../common/components/Padding";
import Center from "../common/components/Center";
import Stack from "../common/components/Stack";
import { useDispatch } from "react-redux";
import { goToNextStep, goToStepIndex } from "../features/journey";
import Button from "../common/components/Button";

const CanYouHearAudioYesNo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Padding>
      <Center>
        <Stack>
          <Button
            onClick={() => {
              dispatch(goToStepIndex("Enter Code"));
            }}
          >
            {t("Yes")}
          </Button>
          <Button onClick={() => dispatch(goToNextStep())}>{t("No")}</Button>
        </Stack>
      </Center>
    </Padding>
  );
};

export default CanYouHearAudioYesNo;
