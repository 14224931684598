import { Era } from "../EraGenreMood";

const _1950sAndEarlier: Era = {
  era: "1950s and earlier",
  value: 1,
  genres: [
    {
      value: 1,
      genre: "Rock and Roll",
      moods: [
        { value: 1, mood: "Party" },
        { value: 2, mood: "Heartbreak" },
        { value: 3, mood: "Romance" },
      ],
    },
    {
      value: 2,
      genre: "Pop",
      moods: [
        { value: 1, mood: "Lost Love" },
        { value: 2, mood: "Found Love" },
        { value: 3, mood: "Workin'" },
      ],
    },
    {
      value: 3,
      genre: "Rhythm & Blues",
      moods: [
        { value: 1, mood: "Party" },
        { value: 2, mood: "Boogie" },
        { value: 3, mood: "Late Night" },
      ],
    },
    {
      value: 4,
      genre: "Country",
      moods: [
        { value: 1, mood: "Heartbreak" },
        { value: 2, mood: "Tex-Mex" },
        { value: 3, mood: "Outlaw" },
      ],
    },
    {
      value: 5,
      genre: "Crooner",
      moods: [
        { value: 1, mood: "Romance" },
        { value: 2, mood: "Introspective" },
        { value: 3, mood: "Swinging" },
      ],
    },
    {
      value: 6,
      genre: "Big Band/Jazz",
      moods: [
        { value: 1, mood: "Dancy" },
        { value: 2, mood: "Jazzy" },
        { value: 3, mood: "Flirty" },
      ],
    },
  ],
};

export default _1950sAndEarlier;
