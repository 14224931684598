import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import VerticalCenter from "../common/components/VerticalCenter";

export default function InPersonNarration2() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <Padding>
          <Stack>
            <h1>{t("Welcome")}</h1>
            <h2>{t("What would help you listen?")}</h2>
          </Stack>
        </Padding>
        <Padding>
          <Stack>
            <h2>
              {t(
                "Would you like to settle in somewhere or move around? You can change anytime."
              )}
            </h2>
          </Stack>
        </Padding>
        <Padding>
          <Stack>
            <h2>
              {t("Feel free to play with your seed pod... but don't open it!")}
            </h2>
          </Stack>
        </Padding>
      </Center>
    </VerticalCenter>
  );
}
