import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";

import {
  whatFontSize,
  FontSize,
} from "../../features/application/ApplicationReducer";

import Padding from "./Padding";

interface FontSizes {
  label: string;
  cssVariableName: FontSize;
}

const fontSizes: FontSizes[] = [
  {
    label: "Default",
    cssVariableName: "font-size-small",
  },
  {
    label: "Medium",
    cssVariableName: "font-size-medium",
  },
  {
    label: "Large",
    cssVariableName: "font-size-large",
  },
];

export default function FontSlider() {
  const fontSize = useSelector(
    (state: RootState) => state.application.fontSize
  );

  const fontSizeValue =
    fontSizes.findIndex((size) => size.cssVariableName === fontSize) + 1;

  const dispatch = useDispatch();

  return (
    <Padding>
      <input
        className="slider"
        type="range"
        min={1}
        max={fontSizes.length}
        defaultValue={fontSizeValue}
        onChange={(value) => {
          const fontSizeValue = fontSizes[Number(value.target.value) - 1];
          dispatch(whatFontSize(fontSizeValue.cssVariableName));
        }}
      />
      <div className="horizontal-labels">
        <p
          className="horizontal-label"
          style={{
            fontSize: "16px",
          }}
        >
          Small
        </p>
        <p
          className="horizontal-label"
          style={{
            fontSize: "20px",
          }}
        >
          Medium
        </p>
        <p
          className="horizontal-label"
          style={{
            fontSize: "24px",
          }}
        >
          Large
        </p>
      </div>
    </Padding>
  );
}
