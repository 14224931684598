import { createRef, useEffect, useState } from "react";

type StickeyTopAndBottom = {
  top: React.ReactElement;
  middle: React.ReactElement;
  bottom?: React.ReactElement;
  showOverflowPrompt?: boolean;
};
const overflowContainer = createRef<HTMLDivElement>();

const StickyTopBottom = (props: StickeyTopAndBottom) => {
  const { showOverflowPrompt = true } = props;
  const hasBottom = props.bottom !== undefined;
  const [showScrollDownPrompt, setShowScrollDownPrompt] =
    useState(showOverflowPrompt);

  const overflowDetection = () => {
    const { current } = overflowContainer;
    if (current) {
      const { scrollHeight, clientHeight, scrollTop } = current;
      const isOverflowed = scrollHeight > clientHeight;
      const isScrollAtBottom = scrollTop + 15 > scrollHeight - clientHeight;
      setShowScrollDownPrompt(isOverflowed && !isScrollAtBottom);
    }
  };

  useEffect(() => overflowDetection(), [props.middle]);

  return (
    <div
      className="top-middle-bottom"
      style={hasBottom ? {} : { gridTemplateRows: "3% 97%" }}
    >
      <div className="top">{props.top}</div>

      <div
        className="middle"
        style={{ height: hasBottom ? "78vh" : "100%" }}
        ref={overflowContainer}
        onScroll={() => overflowDetection()}
      >
        {props.middle}
        {showScrollDownPrompt && (
          <span className="scroll-down-prompt">scroll down</span>
        )}
      </div>
      {hasBottom && <div className="bottom">{props.bottom}</div>}
    </div>
  );
};
export default StickyTopBottom;
