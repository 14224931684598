import * as EraGenreMoods from "./Options/index";

export interface EraAndValue {
  era: string;
  value: number;
}

export interface Era {
  era: string;
  value: number;
  genres: GenreMood[];
}

export interface GenreMood {
  genre: string;
  value: number;
  moods: Mood[];
}

export interface Mood {
  mood: string;
  value: number;
}

const ALL_ERA_GENRE_MOOD_OPTIONS: Era[] = EraGenreMoods.getAll();

const isValidEraGenre = (eraValue: number, genreValueToAssert: number) => {
  const era = ALL_ERA_GENRE_MOOD_OPTIONS.find(
    ({ value }) => value === eraValue
  );
  if (!era) return false;

  const genre = era.genres.filter(({ value }) => value === genreValueToAssert);
  if (!genre) return false;

  return true;
};

const isValidEraGenreMood = (
  eraValue: number,
  genreValue: number,
  moodValueToAssert: number
) => {
  const era = ALL_ERA_GENRE_MOOD_OPTIONS.find(
    ({ value }) => value === eraValue
  );
  if (!era) return false;

  const genres = era.genres.filter(({ value }) => value === genreValue);
  if (!genres) return false;

  const mood = genres.filter(({ value }) => value === moodValueToAssert);
  if (!mood) return false;

  return true;
};

export const isNotValidEraGenreMood = (
  eraValue: number,
  genreValue: number,
  moodValueToAssert: number
) => !isValidEraGenreMood(eraValue, genreValue, moodValueToAssert);

export const isNotValidEraGenre = (
  eraValue: number,
  genreValueToAssert: number
) => !isValidEraGenre(eraValue, genreValueToAssert);

export const isValidEraValue = (eraValue: number) =>
  ALL_ERA_GENRE_MOOD_OPTIONS.map(({ value }) => value).includes(eraValue);

export const isValidMoodValue = (
  eraValue: number,
  genreValue: number,
  moodValue: number
) =>
  ALL_ERA_GENRE_MOOD_OPTIONS.find((era) => era.value === eraValue)
    ?.genres.filter(({ value }) => value === genreValue)[0]
    .moods.map(({ value }) => value)
    .includes(moodValue);

export const isNotValidEraValue = (eraValue: number) =>
  !isValidEraValue(eraValue);

export const getAllEras = (): string[] =>
  ALL_ERA_GENRE_MOOD_OPTIONS.map(({ era }) => era);

export const getAllErasWithValues = (): EraAndValue[] =>
  ALL_ERA_GENRE_MOOD_OPTIONS.map(({ era, value }) => ({ era, value }));

export const getGenresByEraValue = (eraValueToFind: number): GenreMood[] => {
  if (!isValidEraValue(eraValueToFind))
    throw new Error(
      `Unable to getGenresByEra ${eraValueToFind} is no definition`
    );

  return ALL_ERA_GENRE_MOOD_OPTIONS.filter(
    ({ value }) => value === eraValueToFind
  )[0].genres.map((genre) => genre);
};

export const getMoodsByEraAndGenreValue = (
  eraValueToFind: number,
  genreValueToFind: number,
  moodValueToFind: number
): Mood[] => {
  if (!isValidMoodValue(eraValueToFind, genreValueToFind, moodValueToFind))
    throw new Error(
      `Unable to getGenresByEra ${eraValueToFind} is no definition`
    );

  return ALL_ERA_GENRE_MOOD_OPTIONS.filter(
    ({ value }) => value === eraValueToFind
  )[0].genres.filter(({ value }) => value === genreValueToFind)[0].moods;
};
