import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { RootState } from "../app/Store";
import Center from "../common/components/Center";
import Face from "../common/components/Face";
import VerticalCenter from "../common/components/VerticalCenter";
import { selectColor } from "../features/listenerProfile/select";

const PreparingYourFirstAudio = () => {
  const state = useStore().getState() as RootState;
  const faceColor = selectColor(state);
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <Face value={3} color={faceColor} />
        <h1>{t("Thank you")}</h1>
        <p>{t("The key to a good playlist is an old favourite")}</p>
        <p>{t("Let's listen in...")}</p>
      </Center>
    </VerticalCenter>
  );
};
export default PreparingYourFirstAudio;
