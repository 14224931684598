import { Era } from "../EraGenreMood";

const _70s: Era = {
  era: "70s",
  value: 3,
  genres: [
    {
      value: 1,
      genre: "Country",
      moods: [
        { value: 1, mood: "Rebel" },
        { value: 2, mood: "Regret" },
        { value: 3, mood: "Ramblin'" },
      ],
    },
    {
      value: 2,
      genre: "Psychedelic",
      moods: [
        { value: 1, mood: "Darkness" },
        { value: 2, mood: "Outer Space" },
        { value: 3, mood: "Guitar" },
      ],
    },
    {
      value: 3,
      genre: "Rock",
      moods: [
        { value: 1, mood: "Dark" },
        { value: 2, mood: "Glam" },
        { value: 3, mood: "Optimistic" },
      ],
    },
    {
      value: 4,
      genre: "Disco",
      moods: [
        { value: 1, mood: "Heat" },
        { value: 2, mood: "Fun" },
        { value: 3, mood: "Anthem" },
      ],
    },
    {
      value: 5,
      genre: "Motown",
      moods: [
        { value: 1, mood: "Love" },
        { value: 2, mood: "Inspiration" },
        { value: 3, mood: "Groove" },
      ],
    },
    {
      value: 6,
      genre: "Pop",
      moods: [
        { value: 1, mood: "Cruisin'" },
        { value: 2, mood: "Anthem" },
        { value: 3, mood: "Escape" },
      ],
    },
  ],
};

export default _70s;
