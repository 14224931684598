import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import { SOOTHING_SOUNDS } from "../features/acoustics";
import { whatSoothingSound } from "../features/listenerProfile";
import { RootState } from "../app/Store";
import Center from "../common/components/Center";
import PillSingleSelect from "../common/components/PillSingleSelect";

const Question1WhatSoothingSound = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedSoothingSound = useSelector(
    (root: RootState) => root.listenerProfile.soothingSound
  );

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("Which of these sounds do you find the most soothing?")}</h1>
        </Center>
        <Center>
          <Padding>
            <pre>{t("SELECT ONE")}</pre>
          </Padding>
        </Center>
      </Padding>
      <Padding>
        <Stack>
          <PillSingleSelect
            defaultSelectedValue={selectedSoothingSound}
            onClick={(value) => dispatch(whatSoothingSound(value))}
            options={Object.entries(SOOTHING_SOUNDS).map(([sound, value]) => ({
              name: sound.toLocaleUpperCase(),
              value,
            }))}
          />
        </Stack>
      </Padding>
    </>
  );
};
export default Question1WhatSoothingSound;
