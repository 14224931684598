import { useTranslation } from "react-i18next";

import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import FontSlider from "../common/components/FontSlider";

const AppearanceText = () => {
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("Let's set the scene!")}</h1>
        </Center>
      </Padding>
      <Padding>
        <Center>
          <h2>
            {t(
              "You will be reading text and answering questions on your device throughout Remixed. What size would you like the text to be?"
            )}
          </h2>
        </Center>
      </Padding>
      <FontSlider />
    </>
  );
};
export default AppearanceText;
