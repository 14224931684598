import { Era } from "../EraGenreMood";

const _90s: Era = {
  era: "90s",
  value: 5,
  genres: [
    {
      value: 1,
      genre: "Rock",
      moods: [
        { value: 1, mood: "Dark" },
        { value: 2, mood: "Wild" },
        { value: 3, mood: "Beach" },
      ],
    },
    {
      value: 2,
      genre: "Dance Mix",
      moods: [
        { value: 1, mood: "After Hours" },
        { value: 2, mood: "Groove" },
        { value: 3, mood: "Anthem" },
      ],
    },
    {
      value: 3,
      genre: "Country",
      moods: [
        { value: 1, mood: "Rebel" },
        { value: 2, mood: "Hangin' out" },
        { value: 3, mood: "Dance Floor" },
      ],
    },
    {
      value: 4,
      genre: "Pop",
      moods: [
        { value: 1, mood: "Lookin' Good" },
        { value: 2, mood: "Move" },
        { value: 3, mood: "Love" },
      ],
    },
    {
      value: 5,
      genre: "R & B",
      moods: [
        { value: 1, mood: "Jam" },
        { value: 2, mood: "Feels" },
        { value: 3, mood: "Chill" },
      ],
    },
    {
      value: 6,
      genre: "Hip Hop",
      moods: [
        { value: 1, mood: "Worldwide" },
        { value: 2, mood: "West Coast" },
        { value: 3, mood: "East Coast" },
      ],
    },
  ],
};

export default _90s;
