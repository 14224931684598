// TODO: This should not be imported, we shoult take and return `State<T>`
import {
  initialState as initialJourneyState,
  State as JourneyState,
  State,
} from "../features/journey";
import {
  AudioDefaults as initialAudioLibraryState,
  State as LibraryState,
} from "../features/acoustics";
import { RootState } from "../app/Store";
import {
  State as ListenerProfileState,
  initialState as initalListenerProfileState,
} from "../features/listenerProfile";
import {
  State as ApplicationState,
  initialState as initialApplicationState,
} from "../features/application/ApplicationReducer";

const STALL_FLAG_KEY = "stall_flag";
const STATE_STORAGE_INVALIDATE_KEY = "state_invalid_timestamp";
const STATE_STORAGE_KEY = "remixed";
const STATE_STORAGE_TTL = 1000 * 60 * 10;

export const save = (state: State) => {
  localStorage.setItem(STATE_STORAGE_KEY, JSON.stringify(state));
  localStorage.setItem(
    STATE_STORAGE_INVALIDATE_KEY,
    String(Date.now() + STATE_STORAGE_TTL)
  );
};

export const clear = () => {
  localStorage.removeItem(STATE_STORAGE_KEY);
  localStorage.removeItem(STATE_STORAGE_INVALIDATE_KEY);
};

const getSavedRootState = (): RootState | undefined => {
  const rawItemValue: string | null = localStorage.getItem(STATE_STORAGE_KEY);
  if (rawItemValue) {
    const objItemValue = JSON.parse(rawItemValue);
    return objItemValue;
  }
  return undefined;
};

export const previousApplication = (): ApplicationState => {
  const savedRootState = getSavedRootState();
  if (savedRootState) {
    return savedRootState.application as ApplicationState;
  }

  return initialApplicationState;
};

export const previousJourney = (): JourneyState => {
  const savedRootState = getSavedRootState();
  if (savedRootState) {
    return savedRootState.journey as JourneyState;
  }

  return initialJourneyState;
};

export const previousAudioLibrary = (): LibraryState => {
  const savedRootState = getSavedRootState();
  if (savedRootState) {
    return savedRootState.audioLibrary as LibraryState;
  }

  return initialAudioLibraryState;
};

export const previousListenerProfile = (): ListenerProfileState => {
  const savedRootState = getSavedRootState();
  if (savedRootState) {
    return savedRootState.listenerProfile as ListenerProfileState;
  }

  return initalListenerProfileState;
};

const removeStorageIfTTLExpired = () => {
  const storageExpiration = Number(
    localStorage.getItem(STATE_STORAGE_INVALIDATE_KEY)
  );
  if (Date.now() > storageExpiration) {
    clear();
  }
};

export const saveStallFlag = () => {
  localStorage.setItem(STALL_FLAG_KEY, String(true));
};

export const removeStallFalg = () => {
  localStorage.removeItem(STALL_FLAG_KEY);
};

export const hasStallFlag = () => Boolean(localStorage.getItem(STALL_FLAG_KEY));

export const hasValue = () => {
  removeStorageIfTTLExpired();

  const localStorageValue = localStorage.getItem(STATE_STORAGE_KEY) as unknown;
  return !(localStorageValue === null || localStorageValue === undefined);
};
