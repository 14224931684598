import React, { useRef } from "react";
import { Step } from "../../features/journey/paths/Paths";
import Stack from "./Stack";
import gsap from "gsap";

type OnAnimationCompleteFn = () => void;
const useStepAnimation = (
  step: Step,
  onAnimationComplete: OnAnimationCompleteFn
) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const stepExitAnimation = step.onExitAnimation;

  const animateToNextStep = () => {
    const hasExitAnimation = step.onExitAnimation !== undefined;

    if (!hasExitAnimation) {
      onAnimationComplete();
    } else {
      if (step.onExitAnimation === "SLIDE_LEFT") {
        slideLeftToNextStep();
      }
      if (step.onExitAnimation === "SLIDE_UP") {
        slideUpToNextStep();
      }
    }
  };

  const slideUpToNextStep = () => {
    // Target the two specific elements we have forwarded refs to
    gsap.to(wrapper.current, {
      y: -900,
      duration: 0.6,

      yoyo: true,
      onComplete: () => {
        onAnimationComplete();
      },
    });
  };

  const slideLeftToNextStep = () => {
    if (stepExitAnimation) {
      gsap.to(wrapper.current, {
        x: -500,
        duration: 0.4,
        ease: "circ",
        yoyo: true,
        onComplete: () => {
          onAnimationComplete();
          gsap.set(".wrapper", { x: 500 });

          gsap.to(wrapper.current, {
            x: 0,
            duration: 0.4,
            ease: "circ",
            yoyo: true,
          });
        },
      });
    } else {
      onAnimationComplete();
    }
  };

  interface StepAnimationWrapperProps {
    children: React.ReactNode;
  }

  // React.useCallback provides a stable render of the StepAnimationWrapper
  const StepAnimationWrapper = React.useCallback(
    (props: StepAnimationWrapperProps): JSX.Element => (
      <div className="stack wrapper" ref={wrapper}>
        <Stack>{props.children}</Stack>
      </div>
    ),
    [step]
  );

  return { StepAnimationWrapper, animateToNextStep };
};

export default useStepAnimation;
