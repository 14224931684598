import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Stack from "../common/components/Stack";
import Padding from "../common/components/Padding";

const PreparingYourFirstAudioAtHome = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <Padding>
          <Stack>
            <h1>{t("Thank you!")}</h1>
            <h1>{t("We will now prepare your next track.")}</h1>
          </Stack>
        </Padding>
      </Center>
    </VerticalCenter>
  );
};
export default PreparingYourFirstAudioAtHome;
