import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import AudioFlower from "../common/components/AudioFlower";

const InPersonNiceSpendingTimeWithYou = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <AudioFlower animate opacity={0.25} />
        <Padding>
          <h2>
            {t("There's a few minutes of music left. ")}
            {t(
              "When you're ready, please make your way to the door. There's no rush, take your time."
            )}
          </h2>
        </Padding>
      </Center>
    </VerticalCenter>
  );
};

export default InPersonNiceSpendingTimeWithYou;
