import { Era } from "../EraGenreMood";

const _60s: Era = {
  era: "60s",
  value: 2,
  genres: [
    {
      value: 1,
      genre: "Pop groups",
      moods: [
        { value: 1, mood: "Cute" },
        { value: 2, mood: "Hopeful" },
        { value: 3, mood: "Hand Claps" },
      ],
    },
    {
      value: 2,
      genre: "Psychedelic",
      moods: [
        { value: 1, mood: "Fantasy" },
        { value: 2, mood: "Legend" },
        { value: 3, mood: "Unwind" },
      ],
    },
    {
      value: 3,
      genre: "Singer Songwriter",
      moods: [
        { value: 1, mood: "Bop" },
        { value: 2, mood: "Sing Along" },
        { value: 3, mood: "Starting Trouble" },
      ],
    },
    {
      value: 4,
      genre: "Soul",
      moods: [
        { value: 1, mood: "Relaxing" },
        { value: 2, mood: "Attitude" },
        { value: 3, mood: "Uplifting" },
      ],
    },
    {
      value: 5,
      genre: "Folk",
      moods: [
        { value: 1, mood: "Protest" },
        { value: 2, mood: "Discovery" },
        { value: 3, mood: "Introspective" },
      ],
    },
    {
      value: 6,
      genre: "British Invasion",
      moods: [
        { value: 1, mood: "Power" },
        { value: 2, mood: "Bop" },
        { value: 3, mood: "Sweaty" },
      ],
    },
  ],
};

export default _60s;
