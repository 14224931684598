import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import AudioFlower from "../common/components/AudioFlower";

const PlantAnimation = () => {
  return (
    <VerticalCenter>
      <Center>
        <AudioFlower animate />
      </Center>
    </VerticalCenter>
  );
};

export default PlantAnimation;
