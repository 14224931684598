import { useSelector, useStore } from "react-redux";
import { RootState } from "../../app/Store";
import { useDispatch } from "react-redux";
import { goToStepIndex } from "../../features/journey";
import { useEffect, useRef, useState } from "react";
import {
  selectCanFastTravel,
  selectStepIndex,
} from "../../features/journey/select";
import { useTranslation } from "react-i18next";
import Drawer from "react-modern-drawer";

import "react-modern-drawer/dist/index.css";
import Center from "./Center";
import Padding from "./Padding";
import Stack from "./Stack";
import { selectListenerProfile } from "../../features/listenerProfile/select";
import { selectEnableStallFlag } from "../../features/application/select";
import Switcher from "./Switcher";
import { enableStallPrevention } from "../../features/application/ApplicationReducer";

const journeyProgessionMessage = (percentComplete: number) => {
  if (percentComplete < 33) return "It's early";
  if (percentComplete < 50) return "Around halfway";
  if (percentComplete < 80) return "Nearing the end";
  return "Beginning of the end";
};

const DrawerContents = () => {
  const dispatch = useDispatch();
  const state = useStore().getState() as RootState;
  const { t } = useTranslation();
  const currentStepIndex = selectStepIndex(state);
  const listenerProfile = selectListenerProfile(state);
  const stallFlagEnabled = selectEnableStallFlag(state);

  const stepNames = useSelector(
    (state: RootState) => state.journey.pathStepNames
  );
  const journeyStepCount = stepNames.length;
  const journeyCompletionPercent = (100 / journeyStepCount) * currentStepIndex;

  const scrollToRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Move current step into view
    scrollToRef.current?.scrollIntoView();
  }, [currentStepIndex]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Padding>
        <Stack>
          <Switcher>
            <input
              type="checkbox"
              checked={stallFlagEnabled}
              onClick={(e) =>
                dispatch(enableStallPrevention(!stallFlagEnabled))
              }
              id="stallFlag"
            />
            <label htmlFor="stallFlag">Enable stall preventor</label>
          </Switcher>
          <span>#{listenerProfile.userId}</span>
          <Center>
            <h3>{t(journeyProgessionMessage(journeyCompletionPercent))}</h3>
          </Center>
          <progress value={currentStepIndex} max={stepNames.length} />
          <div className="stepWrapper">
            {stepNames.map((name, index) => {
              const isCurrentStep = index === currentStepIndex;
              return (
                <>
                  {isCurrentStep && <div ref={scrollToRef}></div>}
                  <div
                    className={`step ${isCurrentStep ? "active-step" : ""}`}
                    onClick={() => {
                      dispatch(goToStepIndex(name));
                    }}
                    key={`${name}-${index}`}
                  >
                    <Padding>
                      <small>
                        <em>{index + 1}.</em> {name}
                      </small>
                    </Padding>
                  </div>
                </>
              );
            })}
          </div>
        </Stack>
      </Padding>
    </div>
  );
};

const FastTravel = () => {
  const state = useStore().getState() as RootState;
  const canFastTravel = selectCanFastTravel(state);

  const [isFastTravelOpen, setIsFastTravelOpen] = useState(false);

  return (
    <div
      className="fastTravel"
      onClick={() => {
        if (canFastTravel) {
          setIsFastTravelOpen((prevState: boolean) => !prevState);
        }
      }}
    >
      <Drawer
        open={isFastTravelOpen}
        className="drawer"
        onClose={() => setIsFastTravelOpen((prevState: boolean) => !prevState)}
        direction="right"
      >
        <DrawerContents />
      </Drawer>
    </div>
  );
};
export default FastTravel;
