import { useDispatch } from "react-redux";
import CountdownToNextStep from "../common/components/CountdownToNextStep";
import { goToNextStep } from "../features/journey";

export default function JewelEffect() {
  const dispatch = useDispatch();
  const moveToNextStep = () => {
    dispatch(goToNextStep());
  };

  return (
    <div className="jewel-video-container">
      <video
        className="jewel-video"
        autoPlay
        loop
        muted
        playsInline
        src="assets/animations/jewel_video_animation_half_res_reduced.mp4"
        aria-label="Video of a growing small orange ball of light that opens
        into a red flower. Petals emerge from the inside of the flower,
        transforming the flower from red to purple to blue to green. A circle
        of white light appears in the centre of the flower, and the petal fold
        into it. The circle of white light pulses on the screen."
      />
      <div className="jewel-progress-bar-container">
        <CountdownToNextStep onCountdownExpired={moveToNextStep} />
      </div>
    </div>
  );
}
