import { useDispatch } from "react-redux";
import { impact } from "../features/listenerProfile";
import HorizontalSlider from "../common/components/HorizontalSlider";
import { useTranslation } from "react-i18next";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import Center from "../common/components/Center";

export default function Question3Impact() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>
            {t(
              "How many people do you believe are impacted by your decisions?"
            )}
          </h1>
        </Center>
      </Padding>
      <VerticalCenter>
        <HorizontalSlider
          leftElement={<p>{t("Just Me")}</p>}
          values={[1, 2.33, 3.66, 5]}
          defaultValue={2.33}
          onChange={(value) => dispatch(impact(value))}
          rightElement={<p>{t("Everyone")}</p>}
        />
      </VerticalCenter>
    </>
  );
}
