import { useState } from "react";
import PillWithIcon from "./PillWithIcon";

export type OnClickFunction = (valuesList: number[]) => void;

interface ChecklistProps {
  options: Array<string>;
  onClick: OnClickFunction;
  maxNumSelectable?: number;
}

const PillMultiSelect = (props: ChecklistProps) => {
  const [numOfChecked, setNumOfChecked] = useState<number>(0);
  const reachedMaxSelectable =
    numOfChecked >=
    (props.maxNumSelectable === undefined
      ? props.options.length
      : props.maxNumSelectable);

  const [valuesList, setValuesList] = useState<number[]>(
    Array.from(new Array(props.options.length).fill(0))
  );

  const updateValueList = (
    valueIndex: number,
    checkedValue: number
  ): number[] =>
    valuesList.map((value, index) => {
      if (index === valueIndex) return checkedValue;
      return value;
    });

  return (
    <div className="pill-cluster">
      {props.options.map((option: string, index: number) => (
        <PillWithIcon
          key={`${option}-${index}`}
          selected={valuesList[index] === 1}
          onClick={() => {
            const isChecked = valuesList[index] === 0;

            if (isChecked && reachedMaxSelectable) {
              return;
            }

            const newValues = updateValueList(index, isChecked ? 1 : 0);

            setNumOfChecked(isChecked ? numOfChecked + 1 : numOfChecked - 1);

            setValuesList(newValues);
            props.onClick(newValues);
          }}
          label={option}
        ></PillWithIcon>
      ))}
    </div>
  );
};

export default PillMultiSelect;
