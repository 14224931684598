import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Padding from "../common/components/Padding";
import { mostAfraid } from "../features/listenerProfile";
import Center from "../common/components/Center";
import PillMultiSelect from "../common/components/PillMultiSelect";

const FEARS = [
  "Failing & doing the wrong thing",
  "Being unloved",
  "Being worthless",
  "Being insignificant",
  "Being incompetent",
  "Everything going wrong",
  "Being trapped",
  "Being controlled & constrained",
  "Conflict & disconnection",
];

export default function Question2MostAfraid() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("What are you most afraid of today?")}</h1>
        </Center>
        <Center>
          <Padding>
            <pre>{t("SELECT UP TO TWO")}</pre>
          </Padding>
        </Center>
      </Padding>
      <Padding>
        <PillMultiSelect
          options={FEARS.map((fear) => t(fear.toLocaleUpperCase()))}
          maxNumSelectable={2}
          onClick={(valuesList) => dispatch(mostAfraid(valuesList))}
        />
      </Padding>
    </>
  );
}
