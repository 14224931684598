import { select, takeEvery } from "redux-saga/effects";
import { PathType } from "../journey/paths/Paths";
import { goToNextStep, State as JourneyState } from "../journey/JourneyReducer";
import { saveStallFlag } from "../../common/LocalStoragePersister";
import { RootState } from "../../app/Store";
import * as LocalStoragePersister from "../../common/LocalStoragePersister";
import { selectJourney } from "../journey/select";

type RefreshExperineceOnSteps = Record<PathType, number[]>;

const FORCE_REFRESH_ON_STEP: RefreshExperineceOnSteps = {
  IN_PERSON: [19, 38],
  AT_HOME: [],
  SETUP: [],
  CONTINUE_RESTART: [],
  UNDEFINED: [],
  STALL_CONTINUE: [],
  TEST: [],
};

function* detectStalledExperience() {
  const enableStallDetection: boolean = yield select(
    (state): RootState => state.application.enableStallPrevention
  );

  const doWeHaveAPreviousSession = LocalStoragePersister.hasValue();

  if (enableStallDetection && doWeHaveAPreviousSession) {
    const journey: JourneyState = yield select((state) => selectJourney(state));
    const { pathType, stepIndex } = journey;
    const stepIndexesToForceRefresh = FORCE_REFRESH_ON_STEP[pathType];

    const shouldWeRefresh = stepIndexesToForceRefresh.includes(stepIndex);
    if (shouldWeRefresh) {
      saveStallFlag();
      window.location.reload();
    }
  }
}

export function* reactions(): unknown {
  yield takeEvery([goToNextStep.type], detectStalledExperience);
}
