import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: false,
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          "Select your text size:": "Select your text size:",
          "Would you like this to be darker?":
            "Would you like this to be darker?",
          "Is it showtime? Do you have your headphones on?":
            "Is it showtime? Do you have your headphones on?",
          "Select language": "Select language",
          "Place seeds in pod": "Place seeds in pod",
          "Open pod": "Open pod",
          "Pod in the hand": "Pod in the hand",
          "Put the pellet in the pot": "Put the pellet in the pot",
          "Pellet going into the pot": "Pellet going into the pot",
          "Water going into the pot": "Water going into the pot",
          "Pour water on the pellet until the pot is half full":
            "Pour water on the pellet until the pot is half full",
          "A smiley face": "A smiley face",
          "Open envelope": "Open envelope",
          "Listening on headphones": "Listening on headphones",
          "Music note": "Music note",
          "Pot in a box": "Pot in a box",
          "Pinkey swear": "Pinkey swear",
          "An envelope": "An envelope",
          "Take a screenshot": "Take a screenshot",
          "Cheese! Take a screenshot": "Cheese! Take a screenshot",
          "It's early": "It's early",
          "Around halfway": "Around halfway",
          "Nearing the end": "Nearing the end",
          "Beginning of the end": "Beginning of the end",
          "Something went wrong. Please contact the Remixed team!":
            "Something went wrong. Please contact the Remixed team!",
          "Oops! You may have entered the wrong code. Please try again.":
            "Oops! You may have entered the wrong code. Please try again.",
          "Wow, you sure are here early! Come back closer to showtime.":
            "Wow, you sure are here early! Come back closer to showtime.",
          "Your passcode is too short.": "Your passcode is too short.",
          "Step is invalid": "step is invalid",
          "Tried to go to step that is not on the path":
            "Tried to go to a step that is not on the path",
          next: "NEXT",
          Headphones: "Headphones",
          Clock: "Clock",
          Lips: "Lips",
          "Falling petals": "Falling petals",
          "Welcome to Remixed!": "Welcome to Remixed!",
          "Let's get a few things set up.": "Let's get a few things set up.",
          "Press the bird.": "Press the bird.",
          "A Bird": "A Bird",
          "Please come and see us or reach out if you cannot play or hear the audio.":
            "Please come and see us or reach out if you cannot play or hear the audio.",
          Language: "Language",
          Appearance: "Appearance",
          "Enter the 6-digit code": "Enter the 6-digit code",
          start: "START",
          "Please put your headphones on. When you are ready, tap 'Next'.":
            "Please put your headphones on. When you are ready, tap 'Next'.",
          "We acknowledge the land on which we gather is the traditional territory of the Haudenosaunee and Anishinaabe peoples. We honour the Upper Canada Treaties and the Dish With One Spoon Wampum agreement, and our responsibility to care for the resources surrounding us.":
            "We acknowledge the land on which we gather is the traditional territory of the Haudenosaunee and Anishinaabe peoples. We honour the Upper Canada Treaties and the Dish With One Spoon Wampum agreement, and our responsibility to care for the resources surrounding us.",
          "Remixed is performed across geography, playing in homes on the traditional unceded territory of the Wolastoqiyik and Mi'kmaq Peoples. This territory is covered by the Treaties of Peace and Friendship. We are grateful to have the opportunity to work and live on this land":
            "Remixed is performed across geography, playing in homes on the traditional unceded territory of the Wolastoqiyik and Mi'kmaq Peoples. This territory is covered by the Treaties of Peace and Friendship. We are grateful to have the opportunity to work and live on this land",
          "A tree": "A tree",
          "Take a look through the package you received.":
            "Take a look through the package you received.",
          "We put together some items for you to use and play with anytime you want.":
            "We put together some items for you to use and play with anytime you want.",
          "Is there something in there that would help you listen now?":
            "Is there something in there that would help you listen now?",
          "People moving into a theatre space":
            "People moving into a theatre space",
          "We acknowledge that collective change and sustainable futures are inextricable from reconciliation.":
            "We acknowledge that collective change and sustainable futures are inextricable from reconciliation.",
          "Our algorithm will now prepare your next tracks. Do you trust us to find what you need?":
            "Our algorithm will now prepare your next tracks. Do you trust us to find what you need?",
          "A set of musical chairs, with a person moving from one chair to another":
            "A set of musical chairs, with a person moving from one chair to another",
          "We're going to be together for about 45 minutes.":
            "We're going to be together for about 45 minutes.",
          "There are so many distractions these days. Please try to stay with us as best you can.":
            "There are so many distractions these days. Please try to stay with us as best you can.",
          "We're grateful you're here.": "We're grateful you're here.",
          "Do you have the box I sent you nearby?":
            "Do you have the box I sent you nearby?",
          "An open hand": "An open hand",
          "Over to you.": "Over to you.",
          "A bag of goodies": "A bag of goodies",
          "You'll also need a glass of water.":
            "You'll also need a glass of water.",
          "This is your chance to settle in. Do you have everything you need?":
            "This is your chance to settle in. Do you have everything you need?",
          "I'll guide you with my voice, as well as through messages like this. The tracks will play automatically - you don't need to worry about anything.":
            "I'll guide you with my voice, as well as through messages like this. The tracks will play automatically - you don't need to worry about anything.",
          "If you get lost or run into any issues, just message me and I'll help you.":
            "If you get lost or run into any issues, just message me and I'll help you.",
          "Let's set the scene!": "Let's set the scene!",
          "A table": "A table",
          "An open box": "An open box",
          "Items in a box": "Items in a box",
          "Open the box": "Open the box",
          "A box holder": "A box holder",
          "Lay the cloth out on the surface and arrange the other items on top of it":
            "Lay the cloth out on the surface and arrange the other items on top of it",
          "Turn the box into a stand and lean your phone on it like this…":
            "Turn the box into a stand and lean your phone on it like this…",
          "What face best represents you right now?":
            "What face best represents you right now?",
          "Cup of tea": "Cup of tea",
          "What color best represents you?": "What color best represents you?",
          "What best represents your mind right now?":
            "What best represents your mind right now?",
          "Which music did you listen to when you were younger??":
            "Which music did you listen to when you were younger??",
          "What music do you listen to now?":
            "What music do you listen to now?",
          "Which of these sounds do you find the most soothing?":
            "Which of these sounds do you find the most soothing?",
          "Change can be really great, but it can also be really hard. Each story is brief, however some deal with challenging topics in a direct way. We want you to be engaged but not distressed.":
            "Change can be really great, but it can also be really hard. Each story is brief, however some deal with challenging topics in a direct way. We want you to be engaged but not distressed.",
          "OPTIONAL - Do you wish to exclude any of these topics from your playlist?":
            "OPTIONAL - Do you wish to exclude any of these topics from your playlist?",
          "Your playlist is being created based on your answers, expect for the first story which is based on a single question...":
            "Your playlist is being created based on your answers, expect for the first story which is based on a single question...",
          "What kind of change are you most interested in making right now?":
            "What kind of change are you most interested in making right now?",
          "Systemic Change": "Systemic Change",
          "Individual Change": "Individual Change",
          "Welcome back!": "Welcome back!",
          "Would you like to...": "Would you like to...",
          Continue: "Continue",
          "or should we...": "or should we...",
          Restart: "Restart",
          "Set yourself up at a table, if you're not already there":
            "Set yourself up at a table, if you're not already there",
          "Let's learn a little more about you...":
            "Let's learn a little more about you...",
          "How do you feel about change?": "How do you feel about change?",
          "I avoid it": "I avoid it",
          "I love it": "I love it",
          "In general, does change happen to you or do you make change happen?":
            "In general, does change happen to you or do you make change happen?",
          "It happens to me": "It happens to me",
          "I make it happen": "I make it happen",
          "Have you experienced a lot of change in your life?":
            "Have you experienced a lot of change in your life?",
          "It never ends": "It never ends",
          "Which period of your life is/was most formative?":
            "Which period of your life is/was most formative?",
          "Of these options, what do you want more than anything else? Select up to three":
            "Of these options, what do you want more than anything else? Select up to three",
          "Of these options, what infuriates you the most? Select up to three":
            "Of these options, what infuriates you the most? Select up to three",
          "Of these options, what are you currently most afraid of? Select up to three":
            "Of these options, what are you currently most afraid of? Select up to three",
          "Do you hate feeling like other people depend on you?":
            "Do you hate feeling like other people depend on you?",
          "That's me": "That's me",
          "That's not me": "That's not me",
          "Do you shut down when you feel ignored?":
            "Do you shut down when you feel ignored?",
          "Is preserving your identity and independence more important and maintaining relationships?":
            "Is preserving your identity and independence more important and maintaining relationships?",
          "How would you describe your romantic relationships?":
            "How would you describe your romantic relationships?",
          "One person for life": "One person for life",
          "Open to all": "Open to all",
          "How would you describe your relationship with your family?":
            "How would you describe your relationship with your family?",
          "Nothing to report": "Nothing to report",
          "How much time do you have?": "How much time do you have?",
          "We are preparing your next tracks. Hold the white & green pod in your hand while you listen.":
            "We are preparing your next tracks. Hold the white & green pod in your hand while you listen.",
          "Break open your pod": "Break open your pod",
          "Place the contents on the cloth": "Place the contents on the cloth",
          "We're going to ask you our final questions now.":
            "We're going to ask you our final questions now.",
          "They may prompt or remind you of something you want to make happen. They may make you realize that you are perfectly content as is. Either way, we invite you to consider: what comes next?":
            "They may prompt or remind you of something you want to make happen. They may make you realize that you are perfectly content as is. Either way, we invite you to consider: what comes next?",
          "Do you believe that change comes from inside or outside of us?":
            "Do you believe that change comes from inside or outside of us?",
          Inside: "Inside",
          Outside: "Outside",
          "Are you feeling any nudges or desires for something more?":
            "Are you feeling any nudges or desires for something more?",
          Yes: "Yes",
          No: "No",
          "How often are your actions true to your values?":
            "How often are your actions true to your values?",
          "All of the time": "All of the time",
          "None of the time": "None of the time",
          "What is most pressing for you right now?":
            "What is most pressing for you right now?",
          "Change myself": "Change myself",
          "Change the world": "Change the world",
          "How many people do you believe are impacted by your decisions?":
            "How many people do you believe are impacted by your decisions?",
          "Just Me": "Just Me",
          "There's some music left. When you’re ready, please make your way to the door. There’s no rush, take your time.":
            "There's some music left. When you’re ready, please make your way to the door. There’s no rush, take your time.",
          "Knowing when to say goodbye is part of change too, isn’t it?":
            "Knowing when to say goodbye is part of change too, isn’t it?",
          Everyone: "Everyone",
          "If you continue as you are today, your life in five years will be…?":
            "If you continue as you are today, your life in five years will be…?",
          Better: "Better",
          Worse: "Worse",
          "Are you afraid?": "Are you afraid?",
          "Thank you!": "Thank you!",
          "The key to a good playlist is an old favourite":
            "The key to a good playlist is an old favourite",
          "We will now prepare your last tracks.":
            "We will now prepare your last tracks.",
          Send: "Send",
          "Your words of encouragement": "Your words of encouragement",
          "Words of encouragement": "Words of encouragement",
          "Bon voyage!": "Bon voyage!",
          "Plant the seeds in the circular opening of the pellet.":
            "Plant the seeds in the circular opening of the pellet.",
          "Make a promise that you will do what you can to make this change happen.":
            "Make a promise that you will do what you can to make this change happen.",
          "Put the whole thing in the box and close it.":
            "Put the whole thing in the box and close it.",
          "Let's listen to this song together.":
            "Let's listen to this song together.",
          "Thank you for experiencing Remixed with us. We’ll send you an email shortly with care instructions for the seeds you just planted. We’d also love to know what you thought! When you respond to our email, please give us your playlist number:":
            "Thank you for experiencing Remixed with us. We’ll send you an email shortly with care instructions for the seeds you just planted. We’d also love to know what you thought! When you respond to our email, please give us your playlist number:",
          "There's some music left. When you're ready, take your headphones off and find the perfect spot for your growing seeds.":
            "There's some music left. When you're ready, take your headphones off and find the perfect spot for your growing seeds.",
          "It was nice spending time with you.":
            "It was nice spending time with you.",
          "There's a few minutes of music left.When you’re ready, please make your way to the door. There’s no rush, take your time. Knowing when to say goodbye is part of change too, isn’t it?":
            "There's a few minutes of music left.When you’re ready, please make your way to the door. There’s no rush, take your time. Knowing when to say goodbye is part of change too, isn’t it?",
          "Let's go inside.": "Let's go inside.",
          "This is the musical chairs section!":
            "This is the musical chairs section!",
          "Please find a chair before the song stops.":
            "Please find a chair before the song stops.",
          "Let's listen in": "Let's listen in",
          "Let's listen to this song and imagine the possibilities together.":
            "Let's listen to this song and imagine the possibilities together.",
          "Everything going wrong": "Everything going wrong",
          "Being trapped": "Being trapped",
          "Failing & doing the wrong thing": "Failing & doing the wrong thing",
          "Conflict & disconnection": "Conflict & disconnection",
          "Being insignificant": "Being insignificant",
          "Being unloved": "Being unloved",
          "Being worthless": "Being worthless",
          "Being incompetent": "Being incompetent",
          "Being controlled & constrained": "Being controlled",
          "Lack of authenticity": "Lack of authenticity",
          Powerlessness: "Powerlessness",
          "Unreliability & disloyalty": "Unreliability & disloyalty",
          Laziness: "Laziness",
          "Boredom & repetition": "Boredom & repetition",
          "Selfishness & takers": "Selfishness & takers",
          "Lack of drive & ambition": "Lack of drive & ambition",
          Intrusiveness: "Intrusiveness",
          "Be at peace & bring harmony": "Be at peace & bring harmony",
          "Be valued & successful": "Be valued & successful",
          "Feel knowledgeable & competent": "Feel knowledgeable & competent",
          "Express creatively & be seen as special":
            "Express creatively & be seen as special",
          "Improve yourself & the world": "Improve yourself & the world",
          "Know that you're helpful & loved":
            "Know that you're helpful & loved",
          "Be truthful & in charge": "Be truthful & in charge",
          "Know that you are safe & supported":
            "Know that you are safe & supported",
          "Feel good & carefree": "Feel good & carefree",
          "An icon of three coniferous trees in a triangle formation":
            "An icon of three coniferous trees in a triangle formation",
          "An infinity symbol icon representing relationships and interdependence":
            "An infinity symbol icon representing relationships and interdependence",
          "An outline of two hands cupped around two leaves that emerge out the top of the icon":
            "An outline of two hands cupped around two leaves that emerge out the top of the icon",
          "An icon of a sun beginning to rise over water": "An icon of a sun beginning to rise over water",
          "An icon of two mountain peaks with a full moon over top":
            "An icon of two mountain peaks with a full moon over top",
          "An icon of a wave cresting": "An icon of a wave cresting",
          "An icon of a plant sprouting and beginning to grow roots":
            "An icon of a plant sprouting and beginning to grow roots",
          "An icon of the plant with leaves and deeper roots":
            "An icon of the plant with leaves and deeper roots",
          formativePeriodOptions: {
            Childhood: "Childhood",
            Adolescence: "Adolescence",
            "Early Adulthood (20-30)": "Early Adulthood (20-30)",
            "Adulthood (30-65)": "Adulthood (30-65)",
            "Older Adulthood (65+)": "Older Adulthood (65+)",
          },
          storyChoiceOptions: {
            "You are most interested in making systemic change. What if your own story could give you the agency to do so?":
              "You are most interested in making systemic change. What if your own story could give you the agency to do so?",
            "You are most interested in making systemic change. What if the agency to do so starts in your community?":
              "You are most interested in making systemic change. What if the agency to do so starts in your community?",
            "You are most interested in making a change that is both personal and systemic. What if you have to put a part of yourself on the line in order to do so?":
              "You are most interested in making a change that is both personal and systemic. What if you have to put a part of yourself on the line in order to do so?",
            "You are most interested in making personal change. What if your ability to do so challenges your values?":
              "You are most interested in making personal change. What if your ability to do so challenges your values?",
            "You are most interested in making personal change. What if you don't feel you have the agency to do so?":
              "You are most interested in making personal change. What if you don't feel you have the agency to do so?",
          },
          musicTextOptions: {
            "I know you're hip to music from the 1950s and earlier too. Let's listen in!":
              "I know you're hip to music from the 1950s and earlier too. Let's listen in!",
            "I know you dig music from the 60s too. Let's listen in!":
              "I know you dig music from the 60s too. Let's listen in!",
            "I know you groove to music from the 70s too. Let's listen in!":
              "I know you groove to music from the 70s too. Let's listen in!",
            "I know you like music from the 80s too. Word. Let's listen in.":
              "I know you like music from the 80s too. Word. Let's listen in.",
            "I know you've chillaxed to music from the 90s too. Let's listen in!":
              "I know you've chillaxed to music from the 90s too. Let's listen in!",
            "I know you like music from the 2000s too. That's hot. Let's listen in!":
              "I know you like music from the 2000s too. That's hot. Let's listen in!",
            "I know you heart music from the 2010s too. Let's listen in!":
              "I know you heart music from the 2010s too. Let's listen in!",
            "I know you stan music from the 2020s too. Let's listen in!":
              "I know you stan music from the 2020s too. Let's listen in!",
          },
          excludedTopicOptions: {
            Suicide: "Suicide",
            "Sexual Assault": "Sexual Assault",
            Abortion: "Abortion",
            "COVID-19": "COVID-19",
            "Domestic Violence": "Domestic Violence",
          },
          soothingSoundOptions: {
            Campfire: "Campfire",
            Forest: "Forest",
            Rain: "Rain",
            Waves: "Waves",
            Wind: "Wind",
            "Coffee shop": "Coffee shop",
            "Oscillating fan": "Oscillating fan",
            "Evening streets": "Evening streets",
            "Mechanical hum": "Mechanical hum",
            "White Noise": "White Noise",
          },
          languageOptions: {
            English: "English",
            French: "French",
          },
          appearanceOptions: {
            "Dark Mode": "Dark Mode",
            "Light Mode": "Light Mode",
            "Dim Mode": "Dim Mode",
          },
        },
      },
      fr: {
        translation: {
          "Welcome to Remixed!": "Bienvenue à Remixed !",
          "Select language": "Sélectionner la langue",
          English: "Anglais",
          French: "Français",
        },
      },
    },
  });

export default i18n;
