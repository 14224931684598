import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  isNotValidEraGenre,
  isNotValidEraGenreMood,
  isNotValidEraValue,
} from "../acoustics/EraGenreMood/EraGenreMood";

type journeyType = "SETUP" | "AT_HOME" | "IN_PERSON";
export type CheckinType = "MOVE" | "STRETCH" | "EXPRESS";

export interface State {
  journeyType: journeyType;
  userId: number;
  checkinType: CheckinType;
  showId: string;
  faceRightNow: number;
  color: string;
  mind: number;
  eraYoung: number;
  genreYoung: number;
  moodYoung: number;
  eraNow: number;
  genreNow: number;
  moodNow: number;
  soothingSound: number;
  excludeTopics: number[];
  changeType: number;
  changeFeel: number;
  changeMake: number;
  changeHowMuch: number;
  formativePeriod: number[];
  wantThreeThings: number[];
  hateThreeThings: number[];
  mostAfraid: number[];
  dependent: number;
  ignored: number;
  identityIndependence: number;
  relationships: number;
  family: number;
  internalExternal: number;
  wantMore: number;
  actionsValues: number;
  pressing: number;
  impact: number;
  lifeWillBe: number;
  afraid: number;
  wish: string;
}

export const initialState: State = {
  journeyType: "SETUP",
  checkinType: "MOVE",
  userId: -1,
  showId: "",
  faceRightNow: 2,
  color: "black",
  mind: 2,
  eraYoung: 1,
  genreYoung: 1,
  moodYoung: 1,
  eraNow: 1,
  genreNow: 1,
  moodNow: 1,
  soothingSound: 1,
  excludeTopics: [0, 0, 0, 0, 0],
  changeType: 3,
  changeFeel: 3,
  changeMake: 3,
  changeHowMuch: 3,
  formativePeriod: [0, 0, 0, 0, 0],
  wantThreeThings: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  hateThreeThings: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  mostAfraid: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  dependent: 3,
  ignored: 3,
  identityIndependence: 3,
  relationships: 3,
  family: 3,
  internalExternal: 3,
  wantMore: 3,
  actionsValues: 3,
  pressing: 3,
  impact: 3,
  lifeWillBe: 3,
  afraid: 3,
  wish: "Do it!",
};

const isValueBetween = (min: number, max: number, value: number): boolean => {
  if (isNaN(value)) return false;
  if (value < min) return false;
  if (value > max) return false;
  return true;
};

const notBetween1And5 = (value: number) => !isValueBetween(1, 5, value);
const notBetween1And7 = (value: number) => !isValueBetween(1, 7, value);
const notBetween1And10 = (value: number) => !isValueBetween(1, 10, value);
const notBetween0And4 = (value: number) => !isValueBetween(0, 4, value);

const throwIf = (condition: boolean, message: string) => {
  if (condition) {
    throw new Error(`${message}`);
  }
};

export const listenerProfileReducer = createSlice({
  name: "audioProfile",
  initialState,
  reducers: {
    setJourneyType: (state, action: PayloadAction<journeyType>) => {
      state.journeyType = action.payload;
    },

    whatCheckinType: (state, action: PayloadAction<CheckinType>) => {
      state.checkinType = action.payload;
    },

    recievedWish: (state, action: PayloadAction<string>) => {
      state.wish = action.payload;
    },

    whatUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },

    whatShowId: (state, action: PayloadAction<string>) => {
      state.showId = action.payload;
    },

    whatColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },

    whatFace: (state, action: PayloadAction<number>) => {
      const face = action.payload;
      throwIf(
        notBetween1And5(face),
        `whatFace was passed unexpected value ${face}`
      );
      state.faceRightNow = face;
    },

    whatMind: (state, action: PayloadAction<number>) => {
      const mind = action.payload;
      throwIf(
        notBetween1And5(mind),
        `whatMind was passed unexpected value ${mind}`
      );
      state.mind = mind;
    },

    whatMoodWhenYoung: (state, action: PayloadAction<number>) => {
      const moodValue = action.payload;
      const eraValue = state.eraYoung;
      const genreValue = state.genreYoung;

      throwIf(
        isNotValidEraGenreMood(eraValue, genreValue, moodValue),
        `whatMoodWhenYoung was passed an invalid era-genre-mood value ${eraValue}-${genreValue}-${moodValue}`
      );
      state.moodYoung = moodValue;
    },

    whatGenreWhenYoung: (state, action: PayloadAction<number>) => {
      const genreValue = action.payload;
      const eraValue = state.eraYoung;
      throwIf(
        isNotValidEraGenre(eraValue, genreValue),
        `whatGenreWhenYoung was passed an invalid era value ${eraValue}`
      );
      state.genreYoung = genreValue;
    },

    whatEraWhenYoung: (state, action: PayloadAction<number>) => {
      const eraValue = action.payload;
      throwIf(
        isNotValidEraValue(eraValue),
        `whatEraWhenYoung was passed an invalid era value ${eraValue}`
      );
      state.eraYoung = eraValue;
    },

    whatMoodNow: (state, action: PayloadAction<number>) => {
      const moodValue = action.payload;
      const eraValue = state.eraYoung;
      const genreValue = state.genreYoung;

      throwIf(
        isNotValidEraGenreMood(eraValue, genreValue, moodValue),
        `whatMoodNow was passed an invalid era value ${moodValue}`
      );
      state.moodNow = moodValue;
    },

    whatGenreNow: (state, action: PayloadAction<number>) => {
      const genreValue = action.payload;
      const eraValue = state.eraYoung;
      throwIf(
        isNotValidEraGenre(eraValue, genreValue),
        `whatGenreNow was passed an invalid era value ${eraValue}`
      );
      state.genreNow = genreValue;
    },

    whatEraNow: (state, action: PayloadAction<number>) => {
      const eraValue = action.payload;
      throwIf(
        isNotValidEraValue(eraValue),
        `whatEraNow was passed an invalid era value ${eraValue}`
      );
      state.eraNow = eraValue;
    },

    whatSoothingSound: (state, action: PayloadAction<number>) => {
      const soothingSound = action.payload;

      throwIf(
        notBetween1And10(soothingSound),
        `whatSoothingSound was passed an invalid sound value ${soothingSound}`
      );

      state.soothingSound = soothingSound;
    },

    whatExcludeTopics: (state, action: PayloadAction<number[]>) => {
      const excludeTopics = action.payload;
      excludeTopics.map((topicValue) => {
        throwIf(
          notBetween0And4(topicValue),
          `whatExcludeTopics was passed an invalid topic value ${topicValue}`
        );
        return topicValue;
      });

      state.excludeTopics = excludeTopics;
    },

    whatChangeType: (state, action: PayloadAction<number>) => {
      const changeType = action.payload;
      throwIf(
        notBetween1And5(changeType),
        `whatChangeType was passed an invalid change type ${changeType}`
      );
      state.changeType = changeType;
    },

    howChangeFeel: (state, action: PayloadAction<number>) => {
      const changeFeel = action.payload;
      throwIf(
        notBetween1And5(changeFeel),
        `whatChangeType was passed an invalid change type ${changeFeel}`
      );
      state.changeFeel = changeFeel;
    },

    howMakeChange: (state, action: PayloadAction<number>) => {
      const changeMake = action.payload;
      throwIf(
        notBetween1And5(changeMake),
        `howMakeChange was passed an invalid change type ${changeMake}`
      );
      state.changeMake = changeMake;
    },

    changeHowMuch: (state, action: PayloadAction<number>) => {
      const changeHowMuch = action.payload;
      throwIf(
        notBetween1And5(changeHowMuch),
        `changeHowMuch was passed an invalid change type ${changeHowMuch}`
      );
      state.changeHowMuch = changeHowMuch;
    },

    formativePeriod: (state, action: PayloadAction<number[]>) => {
      const formativePeriod = action.payload;
      throwIf(
        formativePeriod.length !== 5,
        "formativePeriod must be an array with 5 integers"
      );
      state.formativePeriod = formativePeriod;
    },

    wantThreeThings: (state, action: PayloadAction<number[]>) => {
      const wantThreeThings = action.payload;
      throwIf(
        wantThreeThings.length !== 9,
        "wantThreeThings must be an array with 7 integers"
      );
      state.wantThreeThings = wantThreeThings;
    },

    hateThreeThings: (state, action: PayloadAction<number[]>) => {
      const hateThreeThings = action.payload;
      throwIf(
        hateThreeThings.length !== 9,
        "hateThreeThings must be an array with 9 integers"
      );
      state.hateThreeThings = hateThreeThings;
    },

    mostAfraid: (state, action: PayloadAction<number[]>) => {
      const mostAfraid = action.payload;
      throwIf(
        mostAfraid.length !== 9,
        "mostAfraid must be an array with 9 integers"
      );
      state.mostAfraid = mostAfraid;
    },

    dependent: (state, action: PayloadAction<number>) => {
      const dependent = action.payload;
      throwIf(
        notBetween1And5(dependent),
        `dependent was passed an invalid change type ${dependent}`
      );
      state.dependent = dependent;
    },

    ignored: (state, action: PayloadAction<number>) => {
      const ignored = action.payload;
      throwIf(
        notBetween1And5(ignored),
        `ignored was passed an invalid change type ${ignored}`
      );
      state.ignored = ignored;
    },

    identityIndependence: (state, action: PayloadAction<number>) => {
      const identityIndependence = action.payload;
      throwIf(
        notBetween1And5(identityIndependence),
        `identityIndependence was passed an invalid change type ${identityIndependence}`
      );
      state.identityIndependence = identityIndependence;
    },

    relationships: (state, action: PayloadAction<number>) => {
      const relationships = action.payload;
      throwIf(
        notBetween1And5(relationships),
        `relationships was passed an invalid change type ${relationships}`
      );
      state.relationships = relationships;
    },

    family: (state, action: PayloadAction<number>) => {
      const family = action.payload;
      throwIf(
        notBetween1And5(family),
        `family was passed an invalid change type ${family}`
      );
      state.family = family;
    },

    internalExternal: (state, action: PayloadAction<number>) => {
      const internalExternal = action.payload;
      throwIf(
        notBetween1And5(internalExternal),
        `internalExternal was passed an invalid change type ${internalExternal}`
      );
      state.internalExternal = internalExternal;
    },

    wantMore: (state, action: PayloadAction<number>) => {
      const wantMore = action.payload;
      throwIf(
        notBetween1And5(wantMore),
        `wantMore was passed an invalid change type ${wantMore}`
      );
      state.wantMore = wantMore;
    },

    actionsValues: (state, action: PayloadAction<number>) => {
      const actionsValues = action.payload;
      throwIf(
        notBetween1And5(actionsValues),
        `actionsValues was passed an invalid change type ${actionsValues}`
      );
      state.actionsValues = actionsValues;
    },

    pressing: (state, action: PayloadAction<number>) => {
      const pressing = action.payload;
      throwIf(
        notBetween1And7(pressing),
        `pressing was passed an invalid change type ${pressing}`
      );
      state.pressing = pressing;
    },

    impact: (state, action: PayloadAction<number>) => {
      const impact = action.payload;
      throwIf(
        notBetween1And5(impact),
        `impact was passed an invalid change type ${impact}`
      );
      state.impact = impact;
    },

    lifeWillBe: (state, action: PayloadAction<number>) => {
      const lifeWillBe = action.payload;
      throwIf(
        notBetween1And5(lifeWillBe),
        `lifeWillBe was passed an invalid change type ${lifeWillBe}`
      );
      state.lifeWillBe = lifeWillBe;
    },

    afraid: (state, action: PayloadAction<number>) => {
      const afraid = action.payload;
      throwIf(
        notBetween1And5(afraid),
        `afraid was passed an invalid change type ${afraid}`
      );
      state.afraid = afraid;
    },

    resumeListenerProfile: (state, action: PayloadAction<State>) => {
      const segments = Object.keys(state) as Array<keyof typeof state>;
      segments.forEach(<K extends keyof typeof state>(segment: K) => {
        state[segment] = action.payload[segment];
      });
    },
  },
});

export const {
  setJourneyType,
  whatUserId,
  whatShowId,
  whatEraWhenYoung,
  whatGenreWhenYoung,
  whatMoodWhenYoung,
  whatEraNow,
  whatGenreNow,
  whatMoodNow,
  whatFace,
  whatColor,
  whatMind,
  whatSoothingSound,
  whatExcludeTopics,
  whatChangeType,
  howChangeFeel,
  howMakeChange,
  changeHowMuch,
  formativePeriod,
  wantThreeThings,
  hateThreeThings,
  mostAfraid,
  dependent,
  ignored,
  identityIndependence,
  relationships,
  family,
  internalExternal,
  wantMore,
  actionsValues,
  pressing,
  impact,
  lifeWillBe,
  afraid,
  resumeListenerProfile,
  recievedWish,
  whatCheckinType,
} = listenerProfileReducer.actions;
export default listenerProfileReducer.reducer;
