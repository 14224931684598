import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import Spacer from "../common/components/Spacer";
import HalfSpacer from "./HalfSpacer";
import PillMultiSelect from "../common/components/PillMultiSelect";
import BouncingAnchor from "../common/components/BouncingAnchor";
import Stack from "../common/components/Stack";
import { useDispatch } from "react-redux";
import { goToNextStep } from "../features/journey";
import Button from "../common/components/Button";

const InPersonLandAcknowledgement2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="land-acknowledgement-2">
      <HalfSpacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_4_sun.svg"
            alt={t("An icon of a sun beginning to rise over water")}
            width="20%"
            className="icon"
          />
          <Padding>
            <h2>
              {t("Let’s take a moment to consider the land you’re on...")}
            </h2>
          </Padding>
          <Padding>
            <h2>
              {t(
                "If you know the language of the Indigenous peoples of this land, feel free to think of the words you know."
              )}
            </h2>
          </Padding>
          <BouncingAnchor anchor="mountains" />
        </Center>
      </VerticalCenter>
      {/* section 2 */}
      <Spacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_5_mountains.svg"
            alt={t("An icon of two mountain peaks with a full moon over top")}
            width="20%"
            id="mountains"
            className="icon"
          />
          <Padding>
            <h2>
              {t(
                "Is your environment one of the following? If you’re in an urban area, what did it used to be?"
              )}
            </h2>
          </Padding>
          <Padding>
            <Stack>
              <pre>{t("CHOOSE ONE")}</pre>
              <PillMultiSelect
                maxNumSelectable={1}
                options={[
                  t("Wet or Dry Forest"),
                  t("Prairie"),
                  t("Wetland"),
                  t("Mountains"),
                  t("Tundra"),
                  t("I'm not sure"),
                ].map((text) => text.toLocaleUpperCase())}
                onClick={(option) => console.log(option)}
              />
            </Stack>
          </Padding>
          <BouncingAnchor anchor="wave" />
        </Center>
      </VerticalCenter>
      {/* section 3 */}
      <Spacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_6_wave.svg"
            alt={t("An icon of a wave cresting")}
            width="20%"
            id="wave"
            className="icon"
          />
          <Padding>
            <h2>{t("What waterbodies are near you?")}</h2>
          </Padding>
          <Padding>
            <Stack>
              <pre>{t("CHECK ALL THAT YOU FEEL CLOSE TO")}</pre>
              <PillMultiSelect
                options={[
                  t("Lakes"),
                  t("Rivers"),
                  t("Oceans"),
                  t("Canals"),
                  t("Bogs"),
                  t("I don't know"),
                ].map((text) => text.toLocaleUpperCase())}
                onClick={(option) => console.log(option)}
              />
            </Stack>
          </Padding>
          <BouncingAnchor anchor="sprout" />
        </Center>
      </VerticalCenter>
      {/* section 4 */}
      <Spacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_7_sprout.svg"
            alt={t("An icon of a plant sprouting and beginning to grow roots")}
            width="20%"
            id="sprout"
            className="icon"
          />
          <Padding>
            <h2>
              {t(
                "Let’s bring awareness to your body as you tune into the land."
              )}
            </h2>
          </Padding>
          <Padding>
            <h2>
              {t("Imagine roots growing from your feet into the ground.")}
            </h2>
          </Padding>
          <Padding>
            <h2>{t("What kind of soil do you imagine you might find?")}</h2>
          </Padding>
          <Padding>
            <Stack>
              <pre>{t("CHOOSE ONE")}</pre>
              <PillMultiSelect
                maxNumSelectable={1}
                options={[
                  t("Gritty and warm"),
                  t("Soft and rich in minerals"),
                  t("Damp and spongy"),
                  t("Large grained and stoney"),
                  t("I wonder..."),
                ].map((text) => text.toUpperCase())}
                onClick={(option) => console.log(option)}
              />
            </Stack>
          </Padding>
          <BouncingAnchor anchor="roots" />
        </Center>
      </VerticalCenter>
      {/* section 5 */}
      <Spacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_8_roots.svg"
            alt={t("An icon of the plant with leaves and deeper roots")}
            width="20%"
            id="roots"
            className="icon"
          />
          <Padding>
            <h2>{t("Picture your roots going deeper, expanding…")}</h2>
          </Padding>
        </Center>
      </VerticalCenter>
      {/* section 6 */}
      <VerticalCenter>
        <Center>
          <Padding>
            <h2>{t("This question is just for you to consider:")}</h2>
          </Padding>
          <Padding>
            <h2>
              {t(
                "How have these conditions shaped you and the changes you make?"
              )}
            </h2>
          </Padding>
        </Center>
      </VerticalCenter>
      <HalfSpacer />
      <Padding>
        <Center>
          <Button
            onClick={() => {
              dispatch(goToNextStep());
            }}
          >
            {t("CONTINUE")}
          </Button>
        </Center>
      </Padding>
    </div>
  );
};

export default InPersonLandAcknowledgement2;
