import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import HalfSpacer from "./HalfSpacer";
import AudioPlaybackProgress from "../common/components/AudioPlaybackProgress";

const EddysStory = () => {
  const { t } = useTranslation();

  return (
    <div className="land-acknowledgement-1">
      <HalfSpacer />
      <VerticalCenter>
        <Center>
          <img
            src="assets/images/landaknow_3_hands.svg"
            alt={t("Hands outstretched")}
            width="20%"
            id="hands"
          />
          <Padding>
            <h3>
              {t(
                "Listen to Remixed story contributor Eddy Charlie sharing about his relationship to Duncan Quw t’sun, the warm lands, and how a walk with his grandfather on this land shaped how he makes change."
              )}
            </h3>
          </Padding>
        </Center>
      </VerticalCenter>
      <VerticalCenter>
        <Padding></Padding>
        <Padding>
          <AudioPlaybackProgress />
        </Padding>
      </VerticalCenter>
      <HalfSpacer />
    </div>
  );
};

export default EddysStory;
