import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AudioName,
  AudioDefaults,
  FirstQuestionnaireAudioSourceUrls,
  SecondQuestionnaireAudioSourceUrls,
  ThirdQuestionnaireAudioSourceUrls,
  IntroAudioSourceUrls,
  State,
  toAbsoluteRemotePath,
  defaultAudio,
} from ".";

export const initialState: State = { ...AudioDefaults };
export const AudioLibrarySlice = createSlice({
  name: "audioLibrary",
  initialState,
  reducers: {
    incrementLoadAttempt: (state, action: PayloadAction<AudioName>) => {
      const audioName = action.payload;
      state[audioName].loadAttempts += 1;
    },

    loadAudio: (state, action: PayloadAction<AudioName>) => {
      const audioName = action.payload;
      state[audioName].status = "LOADING";
      state[audioName].loadAttempts += 1;
    },

    playbackComplete: (state, action: PayloadAction<AudioName>) => {
      const audioName = action.payload;
      state[audioName].status = "FINISHED_PLAYING";
    },

    readyToPlayAudio: (state, action: PayloadAction<AudioName>) => {
      const audioName = action.payload;
      state[audioName].status = "READY_TO_PLAY";
    },

    audioPlayError: (
      state,
      action: PayloadAction<{ audioName: AudioName; message: string }>
    ) => {
      const audioError = action.payload;
      state[audioError.audioName].status = "PLAY_ERROR";
      state[audioError.audioName].error = action.payload.message;
    },

    audioLoadError: (
      state,
      action: PayloadAction<{ audioName: AudioName; message: string }>
    ) => {
      const audioError = action.payload;
      state[audioError.audioName].status = "LOAD_ERROR";
      state[audioError.audioName].error = action.payload.message;
      state[audioError.audioName].loadAttempts += 1;
    },

    startAudio: (
      state,
      action: PayloadAction<{
        audioName: AudioName;
        duration: number;
        externalId: number;
      }>
    ) => {
      state[action.payload.audioName as AudioName].status = "PLAYING";
      state[action.payload.audioName as AudioName].duration =
        action.payload.duration;
      state[action.payload.audioName as AudioName].externalId =
        action.payload.externalId;
      state[action.payload.audioName as AudioName].startTimestamp = Date.now();
    },

    remainingDuration: (
      state,
      action: PayloadAction<{ audioName: AudioName; remainingDuration: number }>
    ) => {
      state[action.payload.audioName as AudioName].remainingDuration =
        action.payload.remainingDuration;
    },

    duration: (
      state,
      action: PayloadAction<{
        audioName: AudioName;
        duration: number;
        externalId: number;
      }>
    ) => {
      state[action.payload.audioName as AudioName].externalId =
        action.payload.externalId;
      state[action.payload.audioName as AudioName].duration = Math.ceil(
        action.payload.duration
      );
    },

    playAudio: (state, action: PayloadAction<AudioName[]>) => {
      Object.keys(state).forEach((audioName) => {
        if (state[audioName as AudioName].status === "PLAYING") {
          state[audioName as AudioName].status = "READY_TO_PLAY";
        }
      });

      action.payload.map((audioName) => {
        state[audioName].status = "PLAYING";
      });
    },

    unloadAudio: (state, action: PayloadAction<AudioName[]>) => {
      action.payload.map((audioName) => {
        state[audioName].status = "NOT_LOADED";
      });
    },

    resetToReadyToPlay: (state, action: PayloadAction<AudioName[]>) => {
      action.payload.forEach((audioName: AudioName) => {
        if (state[audioName].status === "FINISHED_PLAYING") {
          state[audioName].status = "READY_TO_PLAY";
          state[audioName].startTimestamp = -1;
        }
      });
    },

    finishedAudio: (state, action: PayloadAction<AudioName>) => {
      state[action.payload].status = "FINISHED_PLAYING";
      state[action.payload].startTimestamp = -1;
    },

    retrievedIntroAudioSources: (
      state,
      action: PayloadAction<IntroAudioSourceUrls>
    ) => {
      state.intro.sourceUrl = toAbsoluteRemotePath(action.payload.intro);
      state.introInPerson1.sourceUrl = toAbsoluteRemotePath(
        action.payload.introInPerson1
      );
      state.introInPerson2.sourceUrl = toAbsoluteRemotePath(
        action.payload.introInPerson2
      );
      state.questionnaireMusic.sourceUrl = toAbsoluteRemotePath(
        action.payload.questionnaireMusic
      );
      state.transitionSongInPerson.sourceUrl = toAbsoluteRemotePath(
        action.payload.transitionSongInPerson
      );

      state.intro.status = "NOT_LOADED";
      state.introInPerson1.status = "NOT_LOADED";
      state.introInPerson2.status = "NOT_LOADED";
      state.questionnaireMusic.status = "NOT_LOADED";
      state.transitionSongInPerson.status = "NOT_LOADED";
    },

    retrivedFirstQuestionnaireAudioSources: (
      state,
      action: PayloadAction<FirstQuestionnaireAudioSourceUrls>
    ) => {
      state.oldSong.sourceUrl = toAbsoluteRemotePath(action.payload.oldSong);
      state.storySelectionOne.sourceUrl = toAbsoluteRemotePath(
        action.payload.storySelection
      );
      state.soothingTrack.sourceUrl = toAbsoluteRemotePath(
        action.payload.soothingTrack
      );
      state.soothingTrackEvol.sourceUrl = toAbsoluteRemotePath(
        action.payload.soothingTrackEvol
      );
      state.soothingTrackDevol.sourceUrl = toAbsoluteRemotePath(
        action.payload.soothingTrackDevol
      );

      state.oldSong.status = "NOT_LOADED";
      state.storySelectionOne.status = "NOT_LOADED";
      state.soothingTrack.status = "NOT_LOADED";
      state.soothingTrackEvol.status = "NOT_LOADED";
      state.soothingTrackDevol.status = "NOT_LOADED";
    },

    retrivedSecondQuestionnaireAudioSources: (
      state,
      action: PayloadAction<SecondQuestionnaireAudioSourceUrls>
    ) => {
      state.changeSong.sourceUrl = toAbsoluteRemotePath(
        action.payload.changeSong
      );
      state.sceneTwoNarration.sourceUrl = toAbsoluteRemotePath(
        action.payload.narration
      );
      state.story1.sourceUrl = toAbsoluteRemotePath(action.payload.story1);
      state.story2.sourceUrl = toAbsoluteRemotePath(action.payload.story2);

      state.changeSong.status = "NOT_LOADED";
      state.sceneTwoNarration.status = "NOT_LOADED";
      state.story1.status = "NOT_LOADED";
      state.story2.status = "NOT_LOADED";
    },

    retrivedThirdQuestionnaireAudioSources: (
      state,
      action: PayloadAction<ThirdQuestionnaireAudioSourceUrls>
    ) => {
      state.changeStory.sourceUrl = toAbsoluteRemotePath(
        action.payload.changeStory
      );
      state.lastNarration.sourceUrl = toAbsoluteRemotePath(
        action.payload.narration
      );
      state.lastNarration2.sourceUrl = toAbsoluteRemotePath(
        action.payload.narration2
      );
      state.newMusic.sourceUrl = toAbsoluteRemotePath(action.payload.newMusic);

      state.changeStory.status = "NOT_LOADED";
      state.lastNarration.status = "NOT_LOADED";
      state.lastNarration2.status = "NOT_LOADED";
      state.newMusic.status = "NOT_LOADED";
    },

    resumeLibrary: (state, action: PayloadAction<State>) => {
      Object.keys(state).forEach((key) => {
        const audioName = key as AudioName;
        state[audioName] = defaultAudio(action.payload[audioName].sourceUrl);
      });
    },
  },
});

export const {
  retrievedIntroAudioSources,
  retrivedFirstQuestionnaireAudioSources,
  retrivedSecondQuestionnaireAudioSources,
  retrivedThirdQuestionnaireAudioSources,
  playAudio,
  finishedAudio,
  audioLoadError,
  audioPlayError,
  loadAudio,
  readyToPlayAudio,
  playbackComplete,
  startAudio,
  resetToReadyToPlay,
  duration,
  remainingDuration,
  resumeLibrary,
  unloadAudio,
  incrementLoadAttempt,
} = AudioLibrarySlice.actions;
export default AudioLibrarySlice.reducer;
