import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import Center from "../common/components/Center";
import Face from "../common/components/Face";
import VerticalCenter from "../common/components/VerticalCenter";
import { selectColor } from "../features/listenerProfile/select";
import { RootState } from "../app/Store";

const NiceSpendingTimeWithYou = () => {
  const { t } = useTranslation();
  const state = useStore().getState();
  const faceColor = selectColor(state as RootState);

  return (
    <VerticalCenter>
      <Center>
        <Face value={3} color={faceColor} />
        <p>
          {t(
            "There's some music left. When you're ready, take your headphones off and find the perfect spot for your growing seeds."
          )}
        </p>

        <p>{t("It was nice spending time with you.")}</p>
        <p>{t("Over to you.")}</p>
      </Center>
    </VerticalCenter>
  );
};

export default NiceSpendingTimeWithYou;
