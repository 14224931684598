import { useTranslation } from "react-i18next";
import Padding from "../common/components/Padding";
import Switcher from "../common/components/Switcher";
import VerticalCenter from "../common/components/VerticalCenter";

export default function Planting3() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Switcher>
          <img
            width="60px"
            src="assets/images/54.1_Planting.png"
            alt={t("Planting")}
          />
          <p>{t("Plant the seeds in the circular opening of the pellet.")}</p>
        </Switcher>
        <Switcher>
          <p>
            {t(
              "Make a promise that you will do what you can to make this change happen."
            )}
          </p>
          <img
            width="60px"
            src="assets/images/54.2_Promise.png"
            alt={t("Pinkey swear")}
          />
        </Switcher>
        <Switcher>
          <p>{t("Put the whole thing in the box and close it.")}</p>
          <img
            width="60px"
            src="assets/images/54.3_Pot in box.png"
            alt={t("Pot in a box")}
          />
        </Switcher>
        <Switcher>
          <img
            width="60px"
            src="assets/images/54.4_Music.png"
            alt={t("Music note")}
          />{" "}
          <p>{t("Let's listen to this song together.")}</p>
        </Switcher>
        {/* <Center>
          <img
            width="130px"
            src="assets/images/53_listen@2x.png"
            alt={t("Listening on headphones ")}
          />{" "}
        </Center> */}
      </Padding>
    </VerticalCenter>
  );
}
