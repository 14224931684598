import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import { CheckinType, whatCheckinType } from "../features/listenerProfile";
import Stack from "../common/components/Stack";
import CheckinIcon from "../common/components/CheckinIcon";
import PillWithIcon from "../common/components/PillWithIcon";
import { goToNextStep } from "../features/journey";

export interface CheckinOption {
  type: CheckinType;
  description: string;
  svgComponent: React.ReactNode;
  ariaLabel: string;
}

export const CHECKIN_OPTIONS: CheckinOption[] = [
  {
    type: "MOVE",
    svgComponent: <CheckinIcon icon="MOVE" includeBorder={false} />,
    description:
      "Have you outgrown your space? While you listen, take some time to explore our garden.",
    ariaLabel: "an icon of wind blowing",
  },
  {
    type: "STRETCH",
    svgComponent: <CheckinIcon icon="STRETCH" includeBorder={false} />,
    description:
      "You know how flowers grow towards the sun? Raise your body and intention towards the ceiling. Imagine feeling the sun's warmth on your face.",
    ariaLabel: "An icon of a plant with three sets of leaves",
  },
  {
    type: "EXPRESS",
    svgComponent: <CheckinIcon icon="EXPRESS" includeBorder={false} />,
    description:
      "Do you feel like a tree about to explode in colour? Make a gesture, movement or sound that honours what you've received and prepares you for what's to come.",
    ariaLabel: "An icon of a flower with 6 petals",
  },
];

export default function Checkout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedCheckin, setSelectedCheckin] = useState<CheckinType>();

  useEffect(() => {
    if (selectedCheckin) {
      dispatch(whatCheckinType(selectedCheckin));
      dispatch(goToNextStep());
    }
  }, [selectedCheckin]);

  return (
    <>
      <Padding>
        <Center>
          <h2>
            {t(
              "You've been listening for a while! What do you need right now?"
            )}
          </h2>
        </Center>
      </Padding>
      <Center>
        <pre>SELECT ONE</pre>
      </Center>
      <Padding>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Stack>
            {CHECKIN_OPTIONS.map(({ type, svgComponent, ariaLabel }) => (
              <PillWithIcon
                onClick={() => {
                  setSelectedCheckin(type);
                }}
                selected={selectedCheckin === type}
                svgComponent={svgComponent}
                label={type}
                ariaLabel={ariaLabel}
              />
            ))}
          </Stack>
        </div>
      </Padding>
    </>
  );
}
