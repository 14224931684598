import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";

export default function EnterVenue() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <h1>{t("Let's go inside.")}</h1>
      </Center>
    </VerticalCenter>
  );
}
