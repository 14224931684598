import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import { RootState } from "../app/Store";
import { whatMind } from "../features/listenerProfile";
import { selectColor } from "../features/listenerProfile/select";

import Waveform from "./Waveform";

import Center from "../common/components/Center";
import HorizontalSlider from "../common/components/HorizontalSlider";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";

const MIND_DEFAULT = 3;

const Question1WhatEnneagram = () => {
  const dispatch = useDispatch();
  const state = useStore().getState() as RootState;
  const { t } = useTranslation();
  const [mind, setMind] = useState(MIND_DEFAULT);
  const color = selectColor(state);

  return (
    <>
      <Padding>
        <Center>
          <h1>{t("What best represents your mind right now?")}</h1>
        </Center>
      </Padding>
      <VerticalCenter>
        <Center>
          <Waveform title="" color={color} value={mind} />
          <Padding>
            <HorizontalSlider
              values={[5, 4, 3, 2, 1]}
              defaultValue={MIND_DEFAULT}
              onChange={(value) => {
                dispatch(whatMind(value));
                setMind(value);
              }}
            />
          </Padding>
        </Center>
      </VerticalCenter>
    </>
  );
};
export default Question1WhatEnneagram;
