import { useTranslation } from "react-i18next";
import AudioPlaying from "../common/components/AudioPlaying";
import VerticalCenter from "../common/components/VerticalCenter";

export default function HeadphonePrompt() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <AudioPlaying
        title=""
        subtitle={t(
          "Please put your headphones on. When you are ready, tap 'Next'."
        )}
      />
    </VerticalCenter>
  );
}
