import { Audio } from ".";

export const NATURE_SOOTHING_SOUNDS = {
  Campfire: 1,
  Forest: 4,
  Rain: 5,
  Waves: 8,
  Wind: 10,
};

export const HUMAN_MACHINE_SOOTHING_SOUNDS = {
  Fan: 3,
  Streets: 6,
  "Machine hum": 7,
  "White Noise": 9,
};

export const SOOTHING_SOUNDS = {
  ...NATURE_SOOTHING_SOUNDS,
  ...HUMAN_MACHINE_SOOTHING_SOUNDS,
};

const remoteBaseSourceUrl = "https://remixedstories.ca/static/audio/";
export const toAbsoluteRemotePath = (relativePath: string) =>
  `${remoteBaseSourceUrl}${relativePath}`;

export type AudioName =
  | "birds"
  | "bell"
  | "intro"
  | "claireDeLune"
  | "LandAck"
  | "introNarration"
  | "introInPerson1"
  | "introInPerson2"
  | "questionnaireMusic"
  | "transitionSongInPerson"
  | "oldSong"
  | "storySelectionOne"
  | "soothingTrack"
  | "soothingTrackEvol"
  | "soothingTrackDevol"
  | "changeSong"
  | "sceneTwoNarration"
  | "story1"
  | "story2"
  | "changeStory"
  | "lastNarration"
  | "lastNarration2"
  | "newMusic"
  | "theEnd";

export const defaultAudio = (source?: string): Audio => ({
  startTimestamp: -1,
  externalId: -1,
  remainingDuration: -1,
  duration: -1,
  loadAttempts: 0,
  status: !source || source.length === 0 ? "NO_SOURCE" : "NOT_LOADED",
  sourceUrl: !source || source.length === 0 ? "" : source,
  error: "",
});

export const AudioDefaults = {
  birds: defaultAudio("/birds.mp3"),
  claireDeLune: defaultAudio(
    "https://remixedstories.ca/static/audio/music/ClairDeLune.mp3"
  ),
  introNarration: defaultAudio(
    "https://remixedstories.ca/static/audio/narration/V3/IntroNarration.mp3"
  ),
  intro: defaultAudio("/birds.mp3"),
  LandAck: defaultAudio("/assets/audio/landack.mp3"),
  introInPerson1: defaultAudio(),
  bell: defaultAudio("/assets/audio/bell.mp3"),
  introInPerson2: defaultAudio(),
  questionnaireMusic: defaultAudio(),
  transitionSongInPerson: defaultAudio(),
  oldSong: defaultAudio(),
  storySelectionOne: defaultAudio(),
  soothingTrack: defaultAudio(),
  soothingTrackEvol: defaultAudio(),
  soothingTrackDevol: defaultAudio(),
  changeSong: defaultAudio(),
  sceneTwoNarration: defaultAudio(),
  story1: defaultAudio(),
  story2: defaultAudio(),
  changeStory: defaultAudio(),
  lastNarration: defaultAudio(),
  lastNarration2: defaultAudio(),
  newMusic: defaultAudio(),
  theEnd: defaultAudio("/assets/audio/LastSong_Jun8.mp3"),
};

export interface IntroAudioSourceUrls {
  intro: string;
  introInPerson1: string;
  introInPerson2: string;
  questionnaireMusic: string;
  transitionSongInPerson: string;
  userID: number;
}

export interface FirstQuestionnaireAudioSourceUrls {
  oldSong: string;
  storySelection: string;
  soothingTrack: string;
  soothingTrackEvol: string;
  soothingTrackDevol: string;
}

export interface SecondQuestionnaireAudioSourceUrls {
  changeSong: string;
  narration: string;
  story1: string;
  story2: string;
}

export interface ThirdQuestionnaireAudioSourceUrls {
  changeStory: string;
  narration: string;
  narration2: string;
  newMusic: string;
}
