import { RootState } from "../../app/Store";

export const selectListenerProfile = (state: RootState) =>
  state.listenerProfile;
export const selectEraYoung = (state: RootState) =>
  state.listenerProfile.eraYoung;
export const selectSoothingSound = (state: RootState): number =>
  state.listenerProfile.soothingSound;
export const selectFaceRightNow = (state: RootState) =>
  state.listenerProfile.faceRightNow;
export const selectUserId = (state: RootState) => state.listenerProfile.userId;
export const selectRecievedWish = (state: RootState) =>
  state.listenerProfile.wish;
export const selectColor = (state: RootState) => state.listenerProfile.color;
export const selectCheckin = (state: RootState) =>
  state.listenerProfile.checkinType;
