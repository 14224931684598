import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";

export default function InPersonScene2Intro() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h2>
            {t(
              "Our algorithm will now prepare your next tracks. Do you trust us?"
            )}
          </h2>
        </Center>
      </Padding>
    </VerticalCenter>
  );
}
