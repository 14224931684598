import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";

export default function HaveYouArrived() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <h1>{t("Have you arrived?")}</h1>
      </Center>
    </VerticalCenter>
  );
}
