import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";

export default function GuidePrompt() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <img width="80px" src="assets/images/7_Voice.png" alt={t("Lips")} />
        <p>
          {t(
            `I'll guide you with my voice, as well as through messages 
            like this. The tracks will play automatically - you don't 
            need to worry about anything.`
          )}
        </p>
        <p>
          {t(
            "If you get lost or run into any issues, just message me and I'll help you."
          )}
        </p>
      </Center>
    </VerticalCenter>
  );
}
