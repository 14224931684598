import { useTranslation } from "react-i18next";
import VerticalCenter from "../common/components/VerticalCenter";
import { selectUserId } from "../features/listenerProfile/select";
import { RootState } from "../app/Store";
import Center from "../common/components/Center";
import { useStore } from "react-redux";
import Padding from "../common/components/Padding";

export default function Feedback() {
  const { t } = useTranslation();
  const state = useStore().getState() as RootState;
  const userId = selectUserId(state);

  return (
    <VerticalCenter>
      <Center>
        <p>
          {t(
            "Thank you for experiencing Remixed with us. We’ll send you an email shortly with care instructions for the seeds you just planted. We’d also love to know what you thought! When you respond to our email, please give us your playlist number :"
          )}
        </p>
        <h1 style={{ letterSpacing: "6px" }}>
          <strong>{userId}</strong>
        </h1>
        <Padding>
          <p>{t("Cheeeese! Take a screenshot")}</p>
          <img
            width="130px"
            src="assets/images/56_Screenshot.png"
            alt={t("Take a screenshot")}
          />
        </Padding>
      </Center>
    </VerticalCenter>
  );
}
