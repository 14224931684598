import { useTranslation } from "react-i18next";

import Padding from "../common/components/Padding";
import Switcher from "../common/components/Switcher";
import VerticalCenter from "../common/components/VerticalCenter";

const PlantPellet = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Switcher>
          <img
            width="60px"
            src="assets/images/22.1_Pellet in pot.png"
            alt={t("Pellet going into the pot")}
          />
          <p>{t("Put the pellet in the pot")}</p>
        </Switcher>
      </Padding>
      <Padding>
        <Switcher>
          <p>{t("Pour water on the pellet until the pot is half full")}</p>
          <img
            width="60px"
            src="assets/images/22.2_Water in pot.png"
            alt={t("Water going into the pot")}
          />
        </Switcher>
      </Padding>
    </VerticalCenter>
  );
};
export default PlantPellet;
