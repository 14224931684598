import { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { RootState } from "../../app/Store";
import { AudioName } from "../../features/acoustics";
import { getPathStepByIndex } from "../../features/journey/paths/Paths";
import { selectPathType, selectStepIndex } from "../../features/journey/select";

const AudioPlaybackProgress = () => {
  const state = useStore().getState() as RootState;
  const stepIndex = selectStepIndex(state);
  const pathType = selectPathType(state);
  const step = getPathStepByIndex(pathType, stepIndex);

  /**
   * We need this component to re-render to show playback progress, use useSelector to make sure that happens
   */
  const audioLibrary = useSelector((state: RootState) => state.audioLibrary);
  const acoustics = step.acoustics;
  const hasAcoustics =
    acoustics !== undefined && Object.keys(acoustics).length > 0;

  if (!hasAcoustics) return <></>;

  const audioPlaybackToWatch =
    step.allowNext.config?.audioCompletion ?? Object.keys(acoustics)[0];

  const audio = audioLibrary[audioPlaybackToWatch as AudioName];

  const [time, setTime] = useState(Date.now());
  const [playPosition, setPlayPosition] = useState(0);

  useEffect(() => {
    // Force a refresh every second to update the playback progress bar
    const intervalId = setInterval(() => {
      setTime(Date.now());
      if (audio.startTimestamp > -1) {
        setPlayPosition(Math.ceil((time - audio.startTimestamp) / 1000));
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [audio, time]);

  return <progress max={audio.duration} value={playPosition}></progress>;
};
export default AudioPlaybackProgress;
