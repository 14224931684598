import { original } from "immer";
import { State } from "./JourneyReducer";
import { RootState } from "../../app/Store";
import getPath from "./paths/Paths";

export const withState = (writeableState: State) => {
  const journey = original(writeableState);
  if (!journey) {
    throw new Error("Journey slice is undefined");
  }
  return {
    getCurrentStepIndex: () => journey.stepIndex,
    hasNextStep: (step: number) => journey.pathStepNames.length > step,
    hasStep: (stepName: string) => {
      // prettier-ignore
      const found = journey.pathStepNames
        .find((name) => name === stepName)?.length;

      return Number(found) > 0;
    },
    getStepIndexByName: (nameToFind: string) => {
      return journey.pathStepNames.reduce((foundIndex, name, index) => {
        if (name === nameToFind) {
          return index;
        }
        return foundIndex;
      }, -1);
    },
    getNextStepIndex: () => journey.stepIndex + 1,
    getNumberOfJourneySteps: () => journey.pathStepNames.length,
  };
};

export const selectJourney = (state: RootState) => state.journey;
export const selectStepIndex = (state: RootState) => state.journey.stepIndex;
export const selectPathType = (state: RootState) => state.journey.pathType;
export const selectCanFastTravel = (state: RootState) =>
  getPath(selectPathType(state)).canFastTravel;
