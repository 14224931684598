import Toggle from "react-toggle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/Store";
import { startJourney, takeFirstStep } from "../features/journey";
import {
  ThemeType,
  whatTheme,
} from "../features/application/ApplicationReducer";

import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import Button from "../common/components/Button";
import { useEffect } from "react";
import Switcher from "../common/components/Switcher";

const AppearanceVisual = () => {
  const { t } = useTranslation();
  const journeyType = useSelector(
    (state: RootState) => state.listenerProfile.journeyType
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(whatTheme("Dark" as ThemeType));
  }, []);

  return (
    <>
      <Padding>
        <Padding>
          <Center>
            <h1>{t("Let's Set The Scene.")}</h1>
          </Center>
        </Padding>
        <Center>
          <h2>
            {t(
              "We recommend continuing in dark mode as it will be easier for your eyes in the performance space."
            )}
          </h2>
        </Center>
      </Padding>
      <Padding>
        <Center>
          <h2>
            {t("If you would prefer a lighter screen, use the toggle below.")}
          </h2>
        </Center>
      </Padding>
      <Center>
        <Switcher justifyContent="space-around">
          <h2
            style={{
              fontFamily: "var(--secondary-font)",
              color: "var(--surface-3)",
            }}
          >
            {t("LIGHT MODE")}
          </h2>
          <Toggle
            defaultChecked={false}
            icons={false}
            className="toggle"
            onChange={(e) => {
              dispatch(
                whatTheme(e.target.checked ? "Light" : ("Dark" as ThemeType))
              );
            }}
          ></Toggle>
        </Switcher>
      </Center>
      <VerticalCenter>
        <Padding>
          <Center>
            <Button
              onClick={() => {
                dispatch(startJourney(journeyType));
                dispatch(takeFirstStep());
              }}
            >
              {t("next")}
            </Button>
          </Center>
        </Padding>
      </VerticalCenter>
    </>
  );
};
export default AppearanceVisual;
