import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Stack from "../common/components/Stack";
import Padding from "../common/components/Padding";

const Question3Intro = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <Stack>
            <h2>
              {t(
                "It’s time for our final questions. We’re moving beyond the algorithm now – these ones are just for you."
              )}
            </h2>
            <h2>{t("We invite you to consider: what comes next?")}</h2>
          </Stack>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};

export default Question3Intro;
