import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";
import AudioFlower from "../common/components/AudioFlower";
import Stack from "../common/components/Stack";

const InPersonNiceSpendingTime2 = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <AudioFlower animate opacity={0.1} />
        <Padding>
          <Stack>
            <h2>
              {t(
                "Knowing when to say goodbye is part of change too, isn't it?"
              )}
            </h2>
            <h2>{t("It was nice spending time with you.")}</h2>
            <h2>{t("Over to you.")}</h2>
          </Stack>
        </Padding>
      </Center>
    </VerticalCenter>
  );
};

export default InPersonNiceSpendingTime2;
