import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import HorizontalSlider from "../common/components/HorizontalSlider";
import { whatChangeType } from "../features/listenerProfile";
import VerticalCenter from "../common/components/VerticalCenter";

const Question1WhatKindOfChange = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <Center>
          <h1>
            {t(
              "Which kind of change are you most interested in making right now?"
            )}
          </h1>
        </Center>
      </Padding>
      <VerticalCenter>
        <HorizontalSlider
          leftElement={<p>{t("Systemic Change")}</p>}
          values={[1, 2, 3, 4, 5]}
          defaultValue={3}
          onChange={(value) => dispatch(whatChangeType(value))}
          rightElement={<p>{t("Individual Change")}</p>}
        />
      </VerticalCenter>
    </>
  );
};

export default Question1WhatKindOfChange;
