import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";

const Question1End = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h2>
            {t(
              "Change can be really great, but it can also be really hard. Some of the stories in our library deal with challenging topics. We want you to be engaged but not distressed."
            )}
          </h2>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};
export default Question1End;
