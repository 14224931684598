import { useDispatch } from "react-redux";
import {
  resumeJourney,
  startJourney,
  takeFirstStep,
} from "../features/journey";
import * as LocalStoragePersister from "../common/LocalStoragePersister";
import { resumeLibrary } from "../features/acoustics/AudioLibraryReducer";
import { resumeListenerProfile } from "../features/listenerProfile";
import VerticalCenter from "../common/components/VerticalCenter";
import { useTranslation } from "react-i18next";
import { resumeApplication } from "../features/application/ApplicationReducer";
import { useEffect } from "react";
import Padding from "../common/components/Padding";
import Stack from "../common/components/Stack";
import Center from "../common/components/Center";
import Button from "../common/components/Button";

const ContinueRestart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(resumeApplication(LocalStoragePersister.previousApplication()));
  }, []);

  return (
    <VerticalCenter>
      <Center>
        <Padding>
          <h1>{t("Welcome back!")}</h1>
        </Padding>
      </Center>

      <Padding>
        <Stack align="center">
          <h2>{t("Would you like to...")}</h2>
          <Button
            onClick={() => {
              dispatch(resumeJourney(LocalStoragePersister.previousJourney()));
              dispatch(
                resumeLibrary(LocalStoragePersister.previousAudioLibrary())
              );
              dispatch(
                resumeListenerProfile(
                  LocalStoragePersister.previousListenerProfile()
                )
              );
            }}
          >
            {t("Continue")}
          </Button>
        </Stack>
      </Padding>

      <Padding>
        <Stack align="center">
          <h2>{t("or should we...")} </h2>
          <Button
            onClick={() => {
              dispatch(startJourney("SETUP"));
              dispatch(takeFirstStep());
            }}
          >
            {t("Restart")}
          </Button>
        </Stack>
      </Padding>
    </VerticalCenter>
  );
};

export default ContinueRestart;
