import { Howl } from "howler";
import * as HowlerAPI from "../../common/HowlerAPI";

const username = "remixed_app";
const password = "GuillaumeTheGreat";
const authString = `${username}:${password}`;

export type WebAudioEvent = "LOAD" | "LOAD_ERROR" | "ON_PLAY";

export interface WebAudio {
  fadeIn: (volume: number, duration: number) => void;
  fadeOut: (duration: number) => void;
  volume: (vol?: number) => number;
  stop: () => void;
  play: () => void;
  isPlaying: () => boolean;
  onEnd: (onEndFun: () => void) => void;
  onPlay: (onPlayFun: (externalId: number) => void) => void;
  onLoad: (onLoadFun: () => void) => void;
  onLoadError: (onLoadErrorFun: (e: unknown) => void) => void;
  getDuration: () => number;
  unload: () => void;
}

export const getAudio = (sourceUrl: string): WebAudio | undefined => {
  return toWebAudio(HowlerAPI.howlFromSource(sourceUrl));
};

export const downloadAudio = (sourceUrl: string) => {
  const howl = new Howl({
    xhr: {
      method: "GET",
      headers: { authorization: `Basic ${btoa(authString)}` },
    },
    src: sourceUrl,
    preload: false,
  });
  howl.load();
};

const toWebAudio = (howl: HowlerAPI.HowlPrivate): WebAudio => {
  return {
    fadeIn: (vol: number, duration: number) => {
      howl._sounds[0]._parent.fade(0, vol, duration);
    },
    isPlaying: () => {
      return howl.playing();
    },
    stop: () => {
      if (howl) {
        howl._sounds[0]._parent.stop();
      }
    },
    fadeOut: (duration: number) => {
      if (howl && howl.playing()) {
        howl._sounds[0]._parent.fade(howl.volume(), 0, duration);
      }
    },
    onEnd: (onEndFun) => {
      howl._sounds[0]._parent.on("end", onEndFun);
    },
    onLoad: (onLoadFun) => {
      howl._sounds[0]._parent.on("load", onLoadFun);
    },
    onLoadError: (onLoadErrorFun) => {
      howl._sounds[0]._parent.on("loaderror", (e) => onLoadErrorFun(e));
    },
    volume: (vol?: number) => {
      if (vol) {
        return howl.volume(vol);
      }
      return howl.volume();
    },
    play: () => {
      if (!howl.playing()) {
        howl.play();
      }
    },
    onPlay: (onPlayFun) => {
      howl._sounds[0]._parent.on("play", (externalId) => onPlayFun(externalId));
    },
    getDuration: () => howl._sounds[0]._parent._duration as number,
    unload: () => {
      if (howl) {
        howl.unload();
      }
    },
  };
};
