import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Padding from "../common/components/Padding";
import { whatColor } from "../features/listenerProfile/ListenerProfileReducer";
import Center from "../common/components/Center";
import Button from "../common/components/Button";

const COLORS = [
  {
    rgb: "185, 89, 115",
    name: "red",
  },
  {
    rgb: "188, 144, 58",
    name: "yellow",
  },
  {
    rgb: "101, 131, 209",
    name: "blue",
  },
  {
    rgb: "72, 148, 120",
    name: "green",
  },
];

type onSelectColorFunction = (color: string) => void;

interface ColorButtonProps {
  color: string;
  colorName: string;
  onSelectColor: onSelectColorFunction;
  isSelected: boolean;
}

const ColorButton = (props: ColorButtonProps): React.ReactElement => {
  const dispatch = useDispatch();

  const selectedHalo = { boxShadow: `0 0 0px 15px rgb(${props.color}, 0.4)` };

  return (
    <Button
      className="color-button"
      key={props.color}
      aria-label={`a ${props.colorName} circle`}
      onClick={() => {
        props.onSelectColor(props.color);
        dispatch(whatColor(props.colorName));
      }}
      style={{
        backgroundColor: `rgb(${props.color})`,
        ...(props.isSelected ? selectedHalo : {}),
      }}
    >
      &nbsp;
    </Button>
  );
};

const Question1WhatColor = () => {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState<string>("#54a898");

  return (
    <>
      <Padding>
        <Center>
          <h1>
            {t(
              "Let’s check in with your heart. Which colour best represents how you feel right now?"
            )}
          </h1>
        </Center>
      </Padding>
      <Center>
        {COLORS.map(({ rgb, name }) => (
          <ColorButton
            isSelected={name === selectedColor}
            onSelectColor={() => setSelectedColor(name)}
            colorName={name}
            color={rgb}
          />
        ))}
      </Center>
    </>
  );
};
export default Question1WhatColor;
