import { BASE_URL } from "./BaseProxy";

const WISH_URL = `${BASE_URL}/getWish/`;

interface WishResponse {
  wish: string;
}

export const sendWishText = async (
  userId: number,
  wishText: string
): Promise<WishResponse> => {
  const formData = new FormData();

  formData.append("userID", String(userId));
  formData.append("wish", wishText);

  return await fetch(WISH_URL, {
    method: "POST",
    body: formData,
  })
    .then((data) => data.json())
    .catch((err) => {
      return err;
    });
};
