import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";

export default function Scene2Intro() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <h2>
          {t(
            "We are preparing your next tracks. Hold the white & green pod in your hand while you listen."
          )}
        </h2>

        <img
          width="190px"
          src="assets/images/38_Pod in hand.png"
          alt={t("Pod in the hand")}
        />
      </Center>
    </VerticalCenter>
  );
}
