import { all } from "@redux-saga/core/effects";
import { reactions as JourneyReactions } from "./JourneySagas";
import { reactions as QuestionnaireReactions } from "../questionnaire/QuestionnaireSagas";
import { reactions as AudioLoaderReactions } from "../acoustics/AudioLoaderSagas";
import { reactions as AudioPlayerSagas } from "../acoustics/AudioPlayerSagas";
import { reactions as AudioUnloaderReactions } from "../acoustics/AudioUnloaderSagas";
import { reactions as StallPreventor } from "../stallDetector/StallPreventorSagas";

export default function* sagas() {
  yield all([
    JourneyReactions(),
    QuestionnaireReactions(),
    AudioLoaderReactions(),
    AudioUnloaderReactions(),
    AudioPlayerSagas(),
    StallPreventor(),
  ]);
}
