import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/Store";
import { startJourney, takeFirstStep } from "./features/journey/JourneyReducer";
import * as LocalStoragePersister from "./common/LocalStoragePersister";
import PathStepper from "./features/journey/paths/PathStepper";

function App() {
  const dispatch = useDispatch();
  const step = useSelector((state: RootState) => state.journey.stepIndex);
  const application = useSelector((state: RootState) => state.application);
  const listenerProfile = useSelector(
    (state: RootState) => state.listenerProfile
  );
  const { fontSize, theme } = application;

  useEffect(() => {
    const weAreContinuing = LocalStoragePersister.hasValue();
    const weStalledAndReloaded = LocalStoragePersister.hasStallFlag();

    if (weStalledAndReloaded) {
      dispatch(startJourney("STALL_CONTINUE"));
      dispatch(takeFirstStep());
    } else if (weAreContinuing) {
      dispatch(startJourney("CONTINUE_RESTART"));
      dispatch(takeFirstStep());
    } else {
      dispatch(startJourney("SETUP"));
      dispatch(takeFirstStep());
    }
  }, [dispatch]);

  // When we are on a step use PathStepper to display
  return (
    <div
      className="App"
      data-theme={theme.toLowerCase()}
      data-font-size={fontSize.toLocaleLowerCase()}
      data-color={listenerProfile.color}
    >
      {step > -1 && <PathStepper />}
    </div>
  );
}

export default App;
