import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import Switcher from "../common/components/Switcher";
import VerticalCenter from "../common/components/VerticalCenter";

export default function SettleIn() {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <h1>{t("Let's set the scene!")}</h1>
        </Center>

        <Switcher>
          <img
            width="60px"
            src="assets/images/8.1_Table.png"
            alt={t("A Table")}
          />
          <p>{t("Set yourself up at a table, if you're not already there")}</p>
        </Switcher>

        <Switcher justifyContent="center">
          <p>{t("Open the box")}</p>
          <img
            width="60px"
            src="assets/images/8.2_Box_open.png"
            alt={t("An open box")}
          />
        </Switcher>

        <Switcher>
          <img
            width="60px"
            src="assets/images/8.3_Box_items.png"
            alt={t("Items in a box")}
          />
          <p>
            {t(
              "Lay the cloth out on the surface and arrange the other items on top of it"
            )}
          </p>
        </Switcher>

        <Switcher>
          <p>
            {t(
              "Turn the box into a stand and lean your phone on it like this…"
            )}
          </p>
          <img
            width="60px"
            src="assets/images/8.4_Box_holder.png"
            alt={t("A box holder")}
          />
        </Switcher>
      </Padding>
    </VerticalCenter>
  );
}
