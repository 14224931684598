import { useTranslation } from "react-i18next";
import Padding from "./Padding";
import useSystemAudio from "./useSystemAudio";

interface BouncingAnchorProps {
  anchor: string;
}

const BouncingAnchor = (props: BouncingAnchorProps) => {
  const { t } = useTranslation();
  const { click } = useSystemAudio();

  return (
    <Padding>
      <div className="bounce animate__animated animate__flash animate__infinite animate__slower">
        <a
          onClick={() => {
            click.play();
          }}
          href={`#${props.anchor}`}
        >
          <img
            src="assets/images/landaknow_arrow_black-on-green.svg"
            alt={t("Click me to progress to the next paragraph")}
            width="15%"
          />
        </a>
      </div>
    </Padding>
  );
};

export default BouncingAnchor;
