type SystemAudio = "bell" | "click";

type SystemAudios = Record<SystemAudio, HTMLAudioElement>;

const useSystemAudio = (): SystemAudios => {
  return {
    bell: document.getElementById("bell-audio") as HTMLAudioElement,
    click: document.getElementById("click-audio") as HTMLAudioElement,
  };
};

export default useSystemAudio;
