import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { goToNextStep } from "../features/journey";

import Center from "../common/components/Center";
import Padding from "../common/components/Padding";
import VerticalCenter from "../common/components/VerticalCenter";
import Button from "../common/components/Button";

export default function RipPaper() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Center>
        <Padding>
          <h2>{t("It's time to rip open the paper seed pod.")}</h2>
        </Padding>
        <Padding>
          <h2>
            {t(
              "If you need help, get a Remixed team member's attention and we'll come right over."
            )}
          </h2>
        </Padding>
      </Center>
      <Padding>
        <Center>
          <Button
            onClick={() => {
              dispatch(goToNextStep());
            }}
          >
            {t("I've opened the pod")}
          </Button>
        </Center>
      </Padding>
    </VerticalCenter>
  );
}
