import { Era } from "../EraGenreMood";

const _2000s: Era = {
  era: "2000s",
  value: 6,
  genres: [
    {
      value: 1,
      genre: "Pop",
      moods: [
        { value: 1, mood: "Girl Power" },
        { value: 2, mood: "Obsession" },
        { value: 3, mood: "Soulful" },
      ],
    },
    {
      value: 2,
      genre: "Alt Rock",
      moods: [
        { value: 1, mood: "Soulful" },
        { value: 2, mood: "Feel Good" },
        { value: 3, mood: "Dreamy" },
      ],
    },
    {
      value: 3,
      genre: "Club",
      moods: [
        { value: 1, mood: "Edge" },
        { value: 2, mood: "Bass" },
        { value: 3, mood: "Move" },
      ],
    },
    {
      value: 4,
      genre: "Hip Hop",
      moods: [
        { value: 1, mood: "Battle" },
        { value: 2, mood: "Bop" },
        { value: 3, mood: "Electro" },
      ],
    },
    {
      value: 5,
      genre: "Rock",
      moods: [
        { value: 1, mood: "Bright" },
        { value: 2, mood: "Numb" },
        { value: 3, mood: "Lo-Fi" },
      ],
    },
    {
      value: 6,
      genre: "Reggae/Latin",
      moods: [
        { value: 1, mood: "Dancehall" },
        { value: 2, mood: "Sexy" },
        { value: 3, mood: "Vida" },
      ],
    },
  ],
};

export default _2000s;
