import { useState } from "react";
import PillWithIcon from "./PillWithIcon";

export type OnClickFunction = (value: number) => void;

export interface Option {
  name: string;
  value: number;
}

interface ChecklistProps {
  defaultSelectedValue: number;
  options: Option[];
  onClick: OnClickFunction;
  maxNumSelectable?: number;
}

const PillSingleSelect = (props: ChecklistProps) => {
  const [selected, setSelected] = useState(props.defaultSelectedValue);

  return (
    <div className="pill-cluster">
      {props.options.map(({ name, value }) => (
        <PillWithIcon
          key={`${name}-${value}`}
          selected={value === selected}
          onClick={() => {
            setSelected(value);
            props.onClick(value);
          }}
          label={name}
        ></PillWithIcon>
      ))}
    </div>
  );
};

export default PillSingleSelect;
