import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ThemeType = "Dark" | "Light" | "Init";
export type FontSize =
  | "font-size-small"
  | "font-size-large"
  | "font-size-medium";

export interface State {
  theme: ThemeType;
  fontSize: FontSize;
  enableStallPrevention: boolean;
}

export const initialState: State = {
  theme: "Init",
  fontSize: "font-size-medium",
  enableStallPrevention: true,
};

export const applicationReducer = createSlice({
  name: "application",
  initialState,
  reducers: {
    enableStallPrevention: (state, action: PayloadAction<boolean>) => {
      state.enableStallPrevention = action.payload;
    },
    whatTheme: (state, action: PayloadAction<ThemeType>) => {
      state.theme = action.payload;
    },
    whatFontSize: (state, action: PayloadAction<FontSize>) => {
      state.fontSize = action.payload;
    },
    resumeApplication: (state, action: PayloadAction<State>) => {
      state.theme = action.payload.theme;
      state.enableStallPrevention = action.payload.enableStallPrevention;
    },
  },
});

export const {
  whatTheme,
  resumeApplication,
  whatFontSize,
  enableStallPrevention,
} = applicationReducer.actions;

export default applicationReducer.reducer;
