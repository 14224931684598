import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPathStepNames, PathType } from "./paths/Paths";
import { withState } from "./select";

export type JourneyActionTypes = "START_JOURNEY" | "NEXT_STEP";

type status = "NOT_STARTED" | "IN_PROGRESS" | "ERROR";
export interface State {
  status: status;
  statusText: string;
  pathType: PathType;
  pathStepNames: string[];
  stepIndex: number;
}

export const initialState: State = {
  status: "NOT_STARTED",
  statusText: "",
  pathType: "UNDEFINED",
  pathStepNames: [],
  stepIndex: -1,
};

export const journeySlice = createSlice({
  name: "journey",
  initialState,
  reducers: {
    startJourney: (state, action: PayloadAction<PathType>) => {
      state.pathType = action.payload;
      state.pathStepNames = getPathStepNames(action.payload);
    },

    takeFirstStep: (state) => {
      state.stepIndex = 0;
      state.status = "IN_PROGRESS";
    },

    goToNextStep: (state) => {
      const journey = withState(state);

      const nextStepIndex = journey.getNextStepIndex();
      if (journey.hasNextStep(nextStepIndex)) {
        state.stepIndex = nextStepIndex;
      } else {
        state.status = "ERROR";
        state.statusText = `Journey step ${nextStepIndex} nonexistant on path type ${state.pathType}`;
      }
    },

    resumeJourney: (state, action: PayloadAction<State>) => {
      state.status = action.payload.status;
      state.pathType = action.payload.pathType;
      state.pathStepNames = action.payload.pathStepNames;
      state.stepIndex = action.payload.stepIndex;
    },

    goToStepIndex: (state, action: PayloadAction<string>) => {
      const journey = withState(state);
      const stepName = action.payload;
      if (journey.hasStep(stepName)) {
        state.stepIndex = journey.getStepIndexByName(stepName);
      } else {
        state.status = "ERROR";
        state.statusText = `Step doesn't exist "${stepName}" cannot go to step index`;
      }
    },
  },
});

export const {
  startJourney,
  goToNextStep,
  takeFirstStep,
  resumeJourney,
  goToStepIndex,
} = journeySlice.actions;
export default journeySlice.reducer;
