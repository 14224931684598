interface AudioFlowerProps {
  animate: boolean;
  opacity?: number;
}

const AudioFlower = (props: AudioFlowerProps) => {
  const className = props.animate
    ? "animate__animated animate__delay-2s animate__pulse animate__infinite animate__slower"
    : "";

  return (
    <img
      className={className}
      src="assets/images/remixed_flower.svg"
      alt="A monochromatic flower icon matching your colour selection with wispy fluffy petals that pulses gently."
      style={{
        width: "70%",
        filter: "var(--flower-filter)",
        opacity: props.opacity || 0.5,
      }}
    />
  );
};

export default AudioFlower;
