import { Step, AcousticConfig } from "../paths/Paths";
import { getAtHomePathDefinition } from "./AtHomePathDefinition";
import { getInPersonPathDefinition } from "./InPersonPathDefinition";
import ContinueRestart from "../../../components/ContinueRestart";
import EnterCode from "../../../components/EnterCode";
import Welcome from "../../../components/Welcome";
import AppearanceText from "../../../components/AppearanceText";
import AppearanceVisual from "../../../components/AppearanceVisual";
import TakeABreath from "../../../components/TakeABreath";
import CanYouHearAudio from "../../../components/CanYouHearAudio";
import CanYouHearAudioFix1 from "../../../components/CanYouHearAudioFix1";
import CanYouHearAudioFix2 from "../../../components/CanYouHearAudioFix2";
import CanYouHearAudioFix3 from "../../../components/CanYouHearAudioFix3";

/**
 * This file is where we define steps for a journey.
 * A step could
 *  - update the audio profile
 *  - get some tracks from the server + display status
 *  - wait until track playback has completed
 *  - wait until user clicks continue
 */

export const makeAcousticConfig = (config?: {
  volume?: number;
  secondsUntilFadeOut?: number;
  secondsUntilFadeIn?: number;
  fadeInSeconds?: number;
}): AcousticConfig => ({
  volume: 1,
  secondsUntilFadeOut: -1,
  secondsUntilFadeIn: 0,
  fadeInSeconds: -1,
  ...config,
});

export const stallContinue: Step[] = [
  {
    component: <TakeABreath />,
    name: "Take a breath",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    onExitAnimation: "SLIDE_LEFT",
  },
];

export const continueRestartPathSteps: Step[] = [
  {
    component: <ContinueRestart />,
    name: "Continue Restart",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    onExitAnimation: "SLIDE_LEFT",
  },
];

export const setupPathSteps: Step[] = [
  {
    component: <Welcome />,
    name: "Welcome",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
  },
  {
    component: <CanYouHearAudio />,
    name: "Can you hear audio",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    acoustics: {
      claireDeLune: makeAcousticConfig(),
    },
  },
  {
    component: <CanYouHearAudioFix1 />,
    name: "Check headphones/earbuds",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    acoustics: {
      claireDeLune: makeAcousticConfig(),
    },
  },
  {
    component: <CanYouHearAudioFix2 />,
    name: "Cheack audio level",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    acoustics: {
      claireDeLune: makeAcousticConfig(),
    },
  },
  {
    component: <CanYouHearAudioFix3 />,
    name: "Chat with a remixed member",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    acoustics: {
      claireDeLune: makeAcousticConfig(),
    },
  },
  {
    component: <EnterCode />,
    name: "Enter Code",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    acoustics: {
      claireDeLune: makeAcousticConfig({ secondsUntilFadeOut: 0.0001 }),
    },
  },
  {
    component: <AppearanceText />,
    name: "Choose text",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    acoustics: {
      questionnaireMusic: makeAcousticConfig({ fadeInSeconds: 4 }),
    },
  },
  {
    component: <AppearanceVisual />,
    name: "Choose appearance",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
  },
];

export const atHomePathSteps: Step[] = getAtHomePathDefinition();

export const inPersonPathSteps: Step[] = getInPersonPathDefinition();
