import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { initialState, whatExcludeTopics } from "../features/listenerProfile";
import Padding from "../common/components/Padding";
import PillMultiSelect from "../common/components/PillMultiSelect";
import Center from "../common/components/Center";

const TOPICS = [
  "Suicide",
  "Sexual Assault",
  "Abortion",
  "COVID-19",
  "Domestic Violence",
  "Body shaming",
];

const Question1ExcludeTopics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [excludedTopics, setExcludedTopics] = useState<number[]>(
    initialState.excludeTopics
  );

  useEffect(() => {
    if (excludedTopics !== initialState.excludeTopics)
      dispatch(whatExcludeTopics(excludedTopics));
  }, [dispatch, excludedTopics]);

  return (
    <>
      <Padding>
        <Center>
          <h2>
            {t(
              "Do you wish to exclude any of these topics from your playlist? (Optional)."
            )}
          </h2>
          <Padding>
            <pre>{t("SELECT AS MANY AS APPLY")}</pre>
          </Padding>
        </Center>
      </Padding>
      <Padding>
        <PillMultiSelect
          options={TOPICS.map((text) => text.toLocaleUpperCase())}
          onClick={(valuesList) => {
            setExcludedTopics(valuesList);
          }}
        />
      </Padding>

      <Center>
        <Padding>
          <h2>
            {t(
              "If you, or anyone close to you, has experience with these topics, please listen with care."
            )}
          </h2>
        </Padding>
      </Center>
    </>
  );
};
export default Question1ExcludeTopics;
