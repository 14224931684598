import { useTranslation } from "react-i18next";
import Center from "../common/components/Center";
import VerticalCenter from "../common/components/VerticalCenter";
import Padding from "../common/components/Padding";

const LandAcknowledgement = () => {
  const { t } = useTranslation();

  return (
    <VerticalCenter>
      <Padding>
        <Center>
          <img src="assets/images/4_Land.png" alt={t("A tree")} width="20%" />
          <p>
            {t(
              "Remixed is performed across geography, playing in homes on the traditional unceded territory of the Wolastoqiyik and Mi'kmaq Peoples. This territory is covered by the Treaties of Peace and Friendship. We are grateful to have the opportunity to work and live on this land"
            )}
          </p>
          <p>
            {t(
              "We acknowledge that our digital connection, a tool not available to everyone, occurs on sites that have been gathering places for thousands of years."
            )}
          </p>
        </Center>
      </Padding>
    </VerticalCenter>
  );
};

export default LandAcknowledgement;
